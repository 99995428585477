export const Route = {
  HOMEPAGE: {
    Name: "HomePage",
    Path: "/",
    Title: "Home",
  },
  CREATEINITIATIVE: {
    Name: "CreateInitiativePage",
    Path: "/initiative/:id?", // TODO: UPDATE ONCE PAGE IS AVAILABLE i.e. /create-initiative
    Title: "Create New Initiative",
  },
  REVIEWSPAGE: {
    Name: "ReviewsPage",
    Path: "/reviews-and-approvals",
    Title: "Reviews & Approvals",
  },
  STATUSPAGE: {
    Name: "StatusPage",
    Path: "/initiative-status",
    Title: "Initiative Status",
  },
  ACTIVITIESPAGE: {
    Name: "ActivitiesPage",
    Path: "/initiatives-activities",
    Title: "Activities",
  },
  SEARCHPAGE: {
    Name: "SearchPage",
    Path: "/search",
    Title: "Search",
  },
  CALENDARPAGE: {
    Name: "CalendarPage",
    Path: "/calendar",
    Title: "Planner",
  },
  MESSAGESPAGE: {
    Name: "MessagesPage",
    Path: "/initiative-comments", // TODO: UPDATE ONCE PAGE IS AVAILABLE i.e. /initiative-messages
    Title: "Initiative Messages",
  },
  DOCUMENTSPAGE: {
    Name: "DocumentsPage",
    Path: "/initiative-documents",
    Title: "Documents",
  },
  ARCHIVEPAGE: {
    Name: "ArchivePage",
    Path: "/initiatives-archive",
    Title: "Initiatives Archives",
  },
  AUTHPAGE: {
    Name: "AuthPage",
    Path: "/auth",
  },
  NOTFOUNDPAGE: {
    Name: "NotFoundPage",
    Path: "/:pathMatch(.*)*",
  },
};

const OLD_UI_ENDPOINT = import.meta.env.VITE_OLD_UI_ENDPOINT;

export const OLD_UI_ROUTES = {
  DASHBOARD: OLD_UI_ENDPOINT,
  LOOK_UP: `${OLD_UI_ENDPOINT}look-up`,
  ANALYTICS: `${OLD_UI_ENDPOINT}analytics`,
  INITIATIVE: `${OLD_UI_ENDPOINT}initiative`,
  INITIATIVES: `${OLD_UI_ENDPOINT}initiatives`,
  AP_ANALYZER: `${OLD_UI_ENDPOINT}ap-analyzer`,
  INITIATIVE_STATUS: `${OLD_UI_ENDPOINT}initiative-status`,
  UPDATE_ACTIVITIES: `${OLD_UI_ENDPOINT}update-activities`,
  INITIATIVE_ARCHIVE: `${OLD_UI_ENDPOINT}initiatives-archive`,
  INITIATIVE_COMMENTS: `${OLD_UI_ENDPOINT}initiative-comments`,
  RESPONSIBILITY_MATRIX: `${OLD_UI_ENDPOINT}responsibility-matrix`,
  REVIEW_APPROVAL_STATUS: `${OLD_UI_ENDPOINT}review-approval-status`,
};
