<template>
  <NLayoutSider
    bordered
    show-trigger
    collapse-mode="width"
    :collapsed-width="64"
    :width="240"
    :collapsed="collapsed"
    @collapse="collapsed = true"
    @expand="collapsed = false"
    class="sidebar"
  >
    <NText tag="div" class="logo">
      <!-- <router-link to="/">
        <img src="/waypoint-logo.png" />
      </router-link> -->
      <a :href="OLD_UI_ROUTES.DASHBOARD">
        <img src="/waypoint-logo.png" />
      </a>
    </NText>
    <NText tag="div" class="menu-container flex-grow flex-column">
      <NMenu
        :collapsed="collapsed"
        :collapsed-width="64"
        :collapsed-icon-size="22"
        :options="menuOptions"
        :expand-icon="expandIcon"
      />
      <NText tag="div" class="signout-btn">
        <NTooltip trigger="hover" placement="right">
          <template #trigger>
            <NButton round @click="signout">
              <template #icon>
                <n-icon><LogoutIcon /></n-icon>
              </template>
            </NButton>
          </template>
          Log out
        </NTooltip>
      </NText>
    </NText>
  </NLayoutSider>
</template>

<script>
import { h, ref, defineComponent } from "vue";
import { RouterLink } from "vue-router";
import { useStore } from "vuex";
import { NIcon } from "naive-ui";
import { SignOut20Regular as LogoutIcon } from "@vicons/fluent";
import {
  HomeIconMenu,
  SearchIconMenu,
  CalendarIconMenu,
  ChatIconMenu,
  FolderIconMenu,
  PlusIcon,
  StatusIcon,
  TrashIcon,
  FileIcon,
} from "@components/Icons";
import { Route, OLD_UI_ROUTES } from "@config/Constants";

function renderIcon(icon) {
  return () => h(NIcon, null, { default: () => h(icon) });
}

const menuOptions = [
  {
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: Route.CREATEINITIATIVE.Name,
          },
        },
        { default: () => "New Initiative" }
      ),
    key: "create-initiative",
    icon: renderIcon(PlusIcon),
  },
  {
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: Route.REVIEWSPAGE.Name,
            // name: Route.REVIEWSPAGE.Name,
            // params: {
            //   lang: "zh-CN",
            // },
          },
        },
        { default: () => "Reviews & Approvals" }
      ),
    key: "reviews-and-approvals",
    icon: renderIcon(HomeIconMenu),
  },
  {
    label: () =>
      h(
        "a",
        {
          href: OLD_UI_ROUTES.INITIATIVE_STATUS,
          // rel: 'noopenner noreferrer'
        },
        { default: () => "Status" }
      ),
    key: "status",
    icon: renderIcon(StatusIcon),
  },
  {
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: Route.ACTIVITIESPAGE.Name,
          },
        },
        { default: () => "Activities" }
      ),
    key: "activities",
    icon: renderIcon(FileIcon),
  },
  // {
  //   label: () =>
  //     h(
  //       RouterLink,
  //       {
  //         to: {
  //           name: Route.SEARCHPAGE.Name,
  //           // path: "/search",
  //         },
  //       },
  //       { default: () => "Search" }
  //     ),
  //   key: "search",
  //   icon: renderIcon(SearchIconMenu),
  // },
  {
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: Route.CALENDARPAGE.Name,
            // params: {
            //   lang: "zh-CN",
            // },
          },
        },
        { default: () => "Timeline" }
      ),
    key: "timeline",
    icon: renderIcon(CalendarIconMenu),
  },
  {
    label: () =>
      h(
        "a",
        {
          href: OLD_UI_ROUTES.INITIATIVE_COMMENTS,
        },
        { default: () => "Messages" }
      ),
    key: "messages",
    icon: renderIcon(ChatIconMenu),
  },
  {
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: Route.DOCUMENTSPAGE.Name,
          },
        },
        { default: () => "Documents" }
      ),
    key: "documents",
    icon: renderIcon(FolderIconMenu),
  },
  {
    label: () =>
      h(
        "a",
        {
          href: OLD_UI_ROUTES.INITIATIVE_ARCHIVE,
        },
        { default: () => "Archives" }
      ),
    key: "archives",
    icon: renderIcon(TrashIcon),
  },
];

export default defineComponent({
  components: {
    LogoutIcon,
  },
  setup() {
    const store = useStore();

    return {
      signout: () => store.dispatch("Signout"),
      menuOptions,
      collapsed: ref(true),
      expandIcon() {
        return h(NIcon, null, { default: () => h(CaretDown16Filled) });
      },
    };
  },
});
</script>

<style>
.sidebar {
  z-index: 2;
  height: 100vh;
  position: sticky;
  top: 0;
}

.logo {
  text-align: center;
  margin-top: 2.4rem;
}

.sidebar .menu-container {
  margin-top: 2rem;
  justify-content: space-between;
  padding-bottom: 10rem;
}

.sidebar .n-layout-sider-scroll-container {
  display: flex;
  flex-direction: column;
}

.n-layout-sider .n-layout-toggle-button {
  top: 10rem;
}

.signout-btn {
  text-align: center;
}

.signout-btn svg {
  color: #1264ce;
}

.signout-btn:hover svg {
  color: #fff;
}

.signout-btn .n-button {
  padding: 20px 12px;
  border-radius: 4px;
  background: rgba(18, 100, 206, 0.09);
  color: #1a3d6d;
  border-color: rgba(18, 100, 206, 0.09);
}

.n-menu-item-content--disabled a {
  cursor: not-allowed;
  pointer-events: none;
}

#app .n-menu .n-menu-item-content::before {
  background: #0000 !important;
}
</style>
