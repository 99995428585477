export const waypointTheme = {
  common: {
    primaryColor: "#1264CE",
    borderRadius: "8px",
    bodyColor: "#f9f9f9",
    textColor2: "#050C2B",
    errorColor: "#EB5757",
    // disabled color: #DADEE3
    // successColor: #1CB79B
  },
  Button: {
    heightLarge: "56px",
    heightMedium: "32px",
    borderHover: "1px solid #EB5757",
    borderPressed: "1px solid #EB5757",
    borderFocus: "1px solid #EB5757",
    textColorHover: "#fff",
    textColorPressed: "#f7f7f7",
    textColorFocus: "#fff",
    colorHover: "#EB5757",
    colorPressed: "#EB5757",
    colorFocus: "#EB5757",
    colorHoverPrimary: "#06479c",
    colorPressedPrimary: "#185db7",
    colorFocusPrimary: "#1264CE",
  },
  Anchor: {
    // linkTextColorHover: "#050C2B",
  },
  Card: {
    paddingMedium: "0",
  },
  Input: {
    heightLarge: "56px",
    heightMedium: "48px",
    heightSmall: "36px",
    borderHover: "rgba(0,0,0,0)",
    boxShadowFocus: "0 0 0 2px rgb(196 216 243)",
  },
  Menu: {
    // itemTextColor: "#050C2B",
    itemTextColorHover: "#1264CE",
  },
  Typography: {
    textColor: "#050C2B",
    // pText: "#FF0",
  },
  Select: {
    peers: {
      InternalSelection: {
        // textColor: "#FF0000",
      },
    },
  },
};
