<template>
  <div class="auth page">
    <Authenticator :hide-sign-up="true" :form-fields="formFields">
      <template v-slot:header>
        <div style="padding: var(--amplify-space-large); text-align: center">
          <img class="amplify-image" alt="Amplify logo" src="@/assets/login-logo.png" />
        </div>
      </template>
      <template v-slot:sign-in-header>
        <h2
          class="amplify-heading"
          style="padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl)"
        >
          Sign in to Waypoint
        </h2>
      </template>
    </Authenticator>
  </div>
</template>

<script setup>
import { Authenticator } from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";

const formFields = {
  signIn: {
    username: {
      labelHidden: false,
      placeholder: "Enter Your Email",
      isRequired: true,
      label: "Email:",
    },
    password: {
      labelHidden: false,
      placeholder: "Enter Your Password",
      isRequired: true,
      label: "Password:",
    },
  },
};
</script>

<style>
.auth.page {
  height: 100vh;
}

.auth {
  justify-content: center !important;
  align-items: center;
}

.auth .amplify-input {
  height: 7rem;
  line-height: 7rem;
  font-size: 2rem !important;
}

.auth .amplify-label {
  font-size: 2rem !important;
}

.auth [data-amplify-container] {
  width: 100% !important;
}

.auth [data-amplify-container] > div:first-child {
  background: #101a39;
  height: 16rem;
  padding: 0 !important;
}

.auth [data-amplify-container] > div:last-child {
  width: 30%;
  margin: 0 auto;
  margin-top: 8rem;
  border: none;
  padding: 2rem;
}

.auth .amplify-icon {
  font-size: 1.75rem;
}

.auth
  .amplify-button:not(.amplify-button[data-size="small"]):not(.amplify-field__show-password):not(.amplify-button[data-variation="link"]) {
  color: #fff;
  margin-top: 12.5rem;
  height: 6rem !important;
  font-size: 1.8rem !important;
  background: #101a39 !important;
}

.amplify-button[data-size="small"] {
  font-size: 1.2rem;
  color: var(--n-text-color) !important;
}

.auth .amplify-image {
  width: 45rem;
  height: auto;
  margin-top: 1.5rem;
  /* margin-bottom: 3rem; */
}

.auth fieldset.amplify-flex {
  height: 15rem;
  padding-top: 2rem;
}

fieldset .amplify-passwordfield {
  margin-top: 2rem;
}

form[data-amplify-authenticator-signin] .amplify-flex {
  position: relative;
}

form[data-amplify-authenticator-signin] .amplify-flex .amplify-alert {
  position: absolute !important;
  width: 100%;
}

[data-amplify-authenticator] {
  max-width: 100% !important;
}
</style>
