<template>
  <li
    class="node-tree"
    :class="{
      'is-bar': isBar,
      'is-parent tt': hasChildren,
      'is-expanded': showChildren && !isBar,
      collapsed: !showChildren,
    }"
    :style="{ cursor: hasChildren && !isBar ? 'pointer' : 'initial' }"
  >
    <!-- Bar -->
    <div
      class="box cell-height parent-container"
      :style="{
        '--box-bg':
          '#142656' ||
          node.color ||
          '#1a388b' ||
          '#7879f1' ||
          'rgba(120, 121, 241, 0.25)',
        background: 'transparent !important',
        width: `${parseFloat(node.endPoint) - parseFloat(node.startPoint) || 0.1}rem`,
        transform: `translateX(${node.startPoint})`,
      }"
      v-if="(isBar && !hasChildren) || (isBar && !showChildren)"
    >
      <span :class="['meta t12', { 'text-out': parseFloat(node.width) < 25 }]">
        <span>{{ getFormattedDate(node.start_date, node.end_date) }}</span>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <span class="status">
          {{ node.status?.replace(/_/, " ") }}
        </span>
      </span>
    </div>

    <!-- Menu -->
    <div class="label cell-height parent-container" v-else-if="!isBar">
      <vc-donut
        :sections="[{ value: node.percent, color: getRandom.color }]"
        :thickness="100"
        :start-angle="90"
        :size="32"
        v-if="hasChildren"
      />
      <p :class="{ 'flex-column': true, 'parent-item': hasChildren }">
        <span
          v-on="{ click: node.children?.length ? toggleChildren : undefined }"
          class="node-title"
        >
          <span> {{ node.name }} </span>
          <a
            :href="`${OLD_UI_ROUTES.UPDATE_ACTIVITIES}/${node.initiative_id}`"
            target="_blank"
            rel="noopener noreferrer"
            @click.self="undefined"
          >
            <strong v-if="hasChildren">
              {{ node.initiative_id }}
            </strong>
          </a>
        </span>
        <span v-if="hasChildren"> {{ node.percent }}% </span>
      </p>
    </div>
    <span
      v-if="isBar && showChildren"
      :class="{
        'is-bar': isBar && showChildren,
      }"
      :style="{
        display: !hasChildren ? 'none' : 'inline-block',
        width: `${parseFloat(node.endPoint) - parseFloat(node.startPoint) || 0.1}rem`,
        transform: `translateX(${node.startPoint})`,
        'border-bottom': '6px solid #61ab82',
        height: '1rem',
        'margin-bottom': '3rem',
        'margin-top': '1rem',
      }"
    >
      &nbsp;
    </span>
    <ul v-if="hasChildren && showChildren">
      <template v-if="showChildren">
        <node
          v-for="child in node.children"
          :key="child.label"
          :node="child"
          :isBar="isBar"
        ></node>
      </template>
    </ul>
  </li>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "node",
  props: {
    node: Object,
    isBar: {
      type: Boolean,
      default: false,
    },
  },
  beforeMount() {
    if (!this.isBar) return;

    this.emitter.on(`toggle-${this.node.id}`, data => {
      this.toggleChildren(false);
      // scroll into view -> https://stackoverflow.com/questions/53751393/scroll-element-into-view-within-container-but-not-in-browser-window
      const transform = window
        .getComputedStyle(this.$el.querySelector(".box"))
        .getPropertyValue("transform");
      const matrix = new DOMMatrixReadOnly(transform);
      const translateX = matrix.m41;
      const paddinglLeft = 300; // 300px from left
      const wrapper = document.querySelector(".gantt-wrapper");

      wrapper.scrollLeft = translateX - paddinglLeft;
    });
  },
  mounted() {
    const wrapper = document.querySelector(".gantt-wrapper");
    this.wrapperOffsetWidth = wrapper.offsetWidth;
  },
  data: () => ({
    showChildren: false,
  }),
  computed: {
    hasChildren() {
      return Boolean(this.node?.children?.length);
    },
    getRandom() {
      const colors = ["#142656", "#f2c94c", "#62d5c0"];
      const index = Math.floor(Math.random() * 3);

      return {
        color: colors[index],
      };
    },
  },
  methods: {
    toggleChildren(emit = true) {
      this.showChildren = !this.showChildren;
      if (!emit) return;
      this.emitter.emit(`toggle-${this.node.id}`, this.node);
    },
    getFormattedDate(startDate, endDate) {
      startDate = dayjs(startDate);
      endDate = dayjs(endDate);
      const [isSameMonth, isSameYear] = [
        startDate.isSame(endDate, "month"),
        startDate.isSame(endDate, "year"),
      ];
      const date = isSameMonth // sameMonth ? format -> Month Date1 - Date2, Year
        ? `${startDate.format("MMM DD")}-${endDate.format("DD, YYYY")}`
        : isSameYear // sameYear ? format -> Month Date1 - Month Date2, Year
        ? `${startDate.format("MMM DD")} - ${endDate.format("MMM DD, YYYY")}`
        : `${startDate.format("MMM DD, YYYY")} - ${endDate.format("MMM DD, YYYY")}`;

      return date;
    },
  },
};
</script>

<style>
.node-tree:not(.is-parent) {
  /* margin-top: 12px;
  margin-bottom: 12px; */
}

.is-parent {
  margin: 0;
  border-bottom: 1px dashed #e5e5e5;
  /* border-bottom: 1px dashed #777; */
}

.is-parent > .label {
  width: auto;
  padding: 0 0.5rem;
}

.label {
  width: calc(100vh - 41rem);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}

.parent-item {
  font-weight: bold;
  padding-left: 1rem;
  /* font-size: 0.875rem; */
  color: #050c2b; /* use css variables to pass/access colors */
}

.parent-item span:last-child {
  font-size: 1.1rem;
}

.parent-item strong {
  color: #969da5;
}

.parent-item a {
  text-decoration: none;
  position: relative;
  overflow: hidden;
}

.box {
  margin: 0;
  width: 10rem;
  height: 1rem;
  display: flex;
  border-radius: 8px;
  align-items: center;
  position: relative;
}

.box:after {
  content: "";
  width: 100%;
  height: 20px;
  background: var(--box-bg);
  position: absolute;
  border-radius: 5px;
}

.hidden-spacer {
  visibility: hidden;
}

.node-tree.is-bar {
  display: flex;
  flex-direction: column;
}

.cell-height {
  height: 5rem;
}

.node-tree.is-parent {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-left: 3.2rem;
}

/* .node-tree.is-bar.is-parent {
  padding-top: 0.6rem !important;
} */

.node-tree.is-parent > ul {
  margin: 0;
  padding-left: 7rem;
}

.meta {
  padding-left: 5px;
  width: 100%;
  padding: 0 0.5rem;
  display: flex;
  justify-content: space-between;
  z-index: 9;
  padding-top: 1px;
}

.box:not(.collapsed) > .meta {
  color: #ece9e9;
}

.meta .status {
  position: relative;
  text-transform: capitalize;
}

.node-title {
  display: flex;
  text-transform: capitalize;
}

.node-title span {
  width: calc(100vh - 46rem);
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.node-title:hover strong {
  color: var(--n-text-color);
}

.node-title:hover a {
  text-decoration: underline;
}
/* .meta.text-out .status:before {
  content: attr(data-dash);
  position: absolute;
  left: -12px;
} */

.box.collapsed > .meta {
  color: #fafafc;
}

.box > .meta.text-out {
  color: #7879f1;
}

.box > .meta.text-out {
  position: absolute;
  right: -27rem;
  width: 25rem;
  justify-content: flex-start;
}
</style>
