<template>
  <NLayout class="pages" :class="{ auth: isLoggedIn, [$route.name]: true }">
    <Loading :active="isAppLoading" />
    <TempHeader v-if="isLoggedIn" />

    <AppHeader v-if="isLoggedIn && $route.name !== 'CreateInitiativePage'">
      <ReviewApprovalHeader v-if="$route.matched[0]?.name === Route.REVIEWSPAGE.Name" />
    </AppHeader>

    <NLayoutContent>
      <router-view />
    </NLayoutContent>
  </NLayout>
</template>

<script>
import { computed, watch } from "vue";
import { useStore } from "vuex";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import { useNotification } from "naive-ui";
import { Route } from "@config/Constants";
import { AppHeader } from "@components/Common";
import ReviewApprovalHeader from "@components/ReviewApprovalHeader/ReviewApprovalHeader.vue";
import TempHeader from "./TempHeader.vue";

// $route.matched.forEach
export default {
  name: "PagesContainer",
  components: {
    Loading,
    AppHeader,
    TempHeader,
    ReviewApprovalHeader,
  },
  props: {
    isLoggedIn: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();
    const notification = useNotification();

    watch(
      computed(() => store.state.error),
      error => {
        if (!error) return;

        notification[error.type]({
          title: error.message,
          meta: error.meta,
          duration: error.duration ?? 5e3,
        });
      }
    );

    return {
      Route,
      isAppLoading: computed(() => store.state.isAppLoading),
    };
  },
};
</script>

<style>
.pages.auth {
  z-index: 1;
}

.pages.auth .n-layout-content,
.pages.auth .app-header {
  padding-left: 3.2rem;
  padding-right: 3.2rem;
}

.pages.auth .n-layout-content {
  padding-top: 3.2rem;
}

.app-header {
  padding-bottom: 2.2rem;
  background: inherit;
  border-bottom: 1px solid #dadee3;
}

.pages.auth .bread-crumb {
  margin-top: 2.4rem;
  margin-bottom: 1.4rem;
}

.light-blue {
  background: #e7effa;
}

.n-button.light-blue:focus,
.n-button.light-blue:active,
.n-button.light-blue:hover {
  background: #d8e4f5;
  color: var(--n-text-color);
  --n-border-hover: #d8e4f5;
  --n-border-focus: #d8e4f5;
  --n-border-pressed: #d8e4f5;
}

.light-blue svg {
  position: absolute;
  top: -15px;
  left: -15px;
}

.input-placeholder {
  position: absolute;
  left: 1.5rem;
  color: #999;
  font-weight: 500;
}

.n-input.n-input--focus + .input-placeholder,
.input-placeholder.stick {
  /* top: 0rem; */
  font-size: 1.25rem;
  transition: all 0.5s ease;
  transform: translateY(-1.8rem);
}

.n-button--large-type:not(.filter-btn) {
  min-width: 14rem;
}

.empty-card {
  height: 9rem;
  background: rgb(255 255 255 / 65%);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: #ccc;
}

.initiative-sidebar {
  height: calc(100vh - 13rem);
  position: sticky;
  top: 0;
  z-index: 2;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 45.2rem;
  justify-content: flex-start;
  border-right: 1px solid #ccc;
}

.n-data-table-thead .n-data-table-tr > .n-data-table-th {
  background: #f1f5fa;
}

.n-menu .n-menu-item-content--selected {
  background: #f1f5fa;
}

.n-menu .n-menu-item:hover {
  background: #edf1f5;
}

.n-menu .n-menu-item::before {
  left: 0;
  right: 0;
  width: 100%;
}
</style>
