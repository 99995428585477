<template>
  <NInput placeholder="Search" size="large" @input="setSearch" clearable>
    <template #prefix>
      <NIcon size="15">
        <SearchIcon />
      </NIcon>
    </template>
  </NInput>
</template>

<script>
import { SearchIcon } from "@components/Icons";

export default {
  name: "SearchInput",
  methods: {
    setSearch(value) {
      this.$store.commit("SET_SEARCH", value);
    },
  },
  components: {
    SearchIcon,
  },
};
</script>
