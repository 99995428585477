<template>
  <NSpace vertical>
    <NScrollbar>
      <NDataTable
        remote
        :columns="columns"
        :data="approvalStatuses"
        :loading="$store.state.isAppLoading"
        :row-class-name="highlightRow"
        max-height="calc(100vh - 36rem)"
      />
    </NScrollbar>
  </NSpace>
</template>

<script>
import { h } from "vue";
import { NTag } from "naive-ui";
import { mapMutations, mapActions } from "vuex";
import { InitiativesService } from "@services/Initiatives.service";
import { trycatch, capitalize } from "@utils";

const REFETCH = true;
const createColumns = () => {
  return [
    {
      title: "Stakeholder Name",
      key: "name",
    },
    {
      title: "Business Type",
      key: "type",
    },
    {
      title: "Function",
      key: "function",
    },
    {
      title: "Cost Center",
      key: "cost_center",
      width: 250,
      ellipsis: {
        tooltip: true,
      },
      //   sorter: (row1, row2) => row1.current_completion - row2.current_completion,
    },
    {
      title: "Expense Account",
      key: "account",
      //   sorter: (row1, row2) => row1.current_completion - row2.current_completion,
    },
    {
      title: "Status",
      key: "status",
      render(row) {
        const status = capitalize(row.status);
        let type = "info";

        switch (row.status) {
          case "approved":
            type = "success";
            break;

          case "draft":
            type = "warning";
            break;

          case "rejected":
            type = "error";
            break;

          default:
            type = "info";
            break;
        }

        return h(
          NTag,
          {
            style: {
              marginRight: "6px",
            },
            type,
            class: [type],
            bordered: false,
          },
          {
            default: () => status,
          }
        );
      },
    },
  ];
};

export default {
  props: {
    initiativeId: {
      type: Number,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    override: {
      type: Boolean,
      default: false,
    },
    overridden: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    await this.getInitiativeApprovalStatus(this.initiativeId);
  },
  data: () => ({
    columns: createColumns(),
    approvalStatuses: [],
  }),
  methods: {
    ...mapMutations(["SET_LOADING", "SET_ERROR"]),
    ...mapActions(["FetchInitiatives"]),
    async getInitiativeApprovalStatus(initiativeId) {
      if (!initiativeId || initiativeId < 1) {
        console.error("ID not provided.");
        this.SET_ERROR({
          type: "error",
          message: "Some error occurred while fetching initiative approval status.",
          meta: "Try to reload the page.",
        });
        return;
      }

      this.SET_LOADING(true);

      const initiativeStakeholdersPromise =
        InitiativesService.fetchInitiativeStakeholdersManifest(initiativeId);
      const [error, response] = await trycatch(initiativeStakeholdersPromise);

      if (error) {
        this.SET_ERROR({
          type: "error",
          message: "Some error occurred while fetching Duplicate initiatives.",
          meta: "Try to reload the page.",
        });
      }

      this.approvalStatuses = response.data
        ?.sort((a, z) => {
          if (["approved", "rejected"].includes(a.stakeholder_manifest_status.status))
            return -1;
          else return 1;
        })
        ?.sort((a, z) => (a.cost_center == null ? 1 : -1)) // make cost centers come first
        ?.map(stake => {
          return {
            name: stake.waypoint_user.name,
            type: stake.type,
            function: stake.function,
            cost_center: stake.cost_center?.name,
            account: stake.expense_account?.name,
            status: stake.stakeholder_manifest_status?.status,
          };
        });

      this.SET_LOADING(false);
    },
    highlightRow(row) {
      // highlight any rejections OR logged user only
      if (row.status === "rejected") return "highlight-error";
      const loggedUser = this.$store.state.user.name === row.name;
      if (!loggedUser || this.overridden) return;
      return row.status === "approved" ? "highlight-success" : "";
    },
    async updateStatus(status) {
      const payload = {
        user_id: this.$store.state.user.waypoint_user_id,
        initiative_id: this.initiativeId,
        status: this.status,
      };
      const updateStatusPromise = InitiativesService.updateInitiativeStatus(payload);
      const [error, response] = await trycatch(updateStatusPromise);

      if (error) {
        // Error
        return;
      }

      const promise1 = this.getInitiativeApprovalStatus(this.initiativeId);
      const promise2 = this.FetchInitiatives(REFETCH);

      await Promise.all([promise1, promise2]);
    },
    async overrideApprovalStatus() {
      const overridePromise = InitiativesService.overrideInitiativeApproval(
        this.initiativeId
      );
      const [error, response] = await trycatch(overridePromise);

      if (error) {
        // Error
        return;
      }

      const promise1 = this.getInitiativeApprovalStatus(this.initiativeId);
      const promise2 = this.FetchInitiatives(REFETCH);

      await Promise.all([promise1, promise2]);
    },
  },
  watch: {
    async status(status) {
      await this.updateStatus(status);
    },
    async override(value) {
      if (!value) return;
      await this.overrideApprovalStatus();
    },
  },
};
</script>

<style>
.n-card-header .sub-title {
  color: #999;
  font-size: 1.4rem;
  margin-top: 0;
}
</style>
