<template>
  <div class="gantt">
    <div class="gantt-container flex">
      <div class="initiative-sidebar flex-column" ref="sidebar">
        <NSpace class="gantt-search no-margin">
          <NInput
            type="text"
            size="large"
            @input="val => (searchText = val)"
            placeholder="Search"
            clearable
          >
            <template #prefix>
              <Icon>
                <SearchIcon />
              </Icon>
            </template>
          </NInput>
        </NSpace>
        <NodeTree
          v-for="initiative in getInitiatives"
          :key="initiative.id"
          :node="initiative"
        />
      </div>
      <div
        class="gantt-wrapper"
        :style="{
          width: getGridWidth.width,
          '--wrapper-width': getGridWidth.width,
        }"
        ref="wrapper"
      >
        <div class="gantt-header sticky">
          <div class="years flex">
            <div class="year flex-grow" v-for="year in getYears.all" :key="year">
              {{ year }}
            </div>
          </div>
          <div class="quarters flex">
            <template v-for="(width, index) in getCellPositions" :key="width">
              <div class="quarter flex-grow" :style="quarterStyle(width)">
                <span class="t12 bold600">Q{{ ++index % 4 || 4 }}</span>
                <!-- <p class="quarter-value" style>$125,000</p> -->
              </div>
            </template>
          </div>
        </div>
        <div class="gantt-grid" :style="{ width: getGridWidth.paddedWidth }">
          <Tree
            v-for="(initiative, index) in getInitiatives"
            :key="initiative.id"
            :treeData="initiative"
            :class="[index % 2 == 0 ? 'even' : 'odd']"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import Tree from "./Tree.vue";
import NodeTree from "./NodeTree.vue";
import { Activity } from "@config/Constants";

const QUARTER_WIDTH = 30; // rem
export default {
  props: {
    data: Array,
    years: Array,
    monthsInQuarter: {
      type: Number,
      default: 3,
    },
  },
  mounted() {
    let isSyncingLeftScroll = false;
    let isSyncingRightScroll = false;
    let leftDiv = this.$refs.sidebar;
    let rightDiv = this.$refs.wrapper;

    leftDiv.onscroll = function () {
      if (!isSyncingLeftScroll) {
        isSyncingRightScroll = true;
        rightDiv.scrollTop = leftDiv.scrollTop;
      }
      isSyncingLeftScroll = false;
    };

    rightDiv.onscroll = function () {
      if (!isSyncingRightScroll) {
        isSyncingLeftScroll = true;
        leftDiv.scrollTop = rightDiv.scrollTop;
      }
      isSyncingRightScroll = false;
    };
  },
  data() {
    return {
      QUARTER_WIDTH,
      MONTH_IN_QUARTER_WIDTH: QUARTER_WIDTH / this.monthsInQuarter, // 3 months in one Quarter -> 15/3 = 5rem
      initiatives: [],
      searchText: "",
    };
  },
  computed: {
    getYears() {
      const { years } = this;

      if (years.length == 0) return { first: 0, last: 0, all: 0 };

      years.sort();

      let firstYear = years[0];
      const lastYear = years[years.length - 1];
      const diff = lastYear - firstYear + 1; // 1 includes last year in the difference
      const allYears = Array.from({ length: diff }, (_, i) => firstYear + i);

      return {
        first: firstYear,
        last: lastYear,
        all: allYears,
      };
    },
    getFirstYear() {
      return this.years.sort()[0] ?? new Date().getFullYear();
    },
    getCellPositions() {
      return Array.from(
        { length: this.getYears.all.length * 4 },
        (undef, index) => `${index * this.QUARTER_WIDTH}rem`
      );
    },
    getGridWidth() {
      const QUARTERS_IN_A_YEAR = 4;
      const TOTAL_YEARS_LENGTH = this.getYears.all.length;
      const paddedWidth = `${8 * QUARTERS_IN_A_YEAR * this.QUARTER_WIDTH + 10}rem`; // 10 is padding in rem
      const gridWidth = `${
        TOTAL_YEARS_LENGTH * QUARTERS_IN_A_YEAR * this.QUARTER_WIDTH
      }rem`; // 10 is removed
      const cellWidth = `${
        parseFloat(gridWidth) / (TOTAL_YEARS_LENGTH * QUARTERS_IN_A_YEAR)
      }rem`;

      return {
        paddedWidth,
        width: gridWidth,
        cellWidth,
      };
    },
    getSortedInitiatives() {
      const initiatives = this.data.map(ini => {
        const activitiesSortedAscending = [...ini.children];
        const activitiesSortedDescending = [...ini.children]; // needed to find percentage completion of activities i.e. see method: findInitiativePercentage

        activitiesSortedAscending.sort(
          (a, z) => new Date(a.start_date) - new Date(z.start_date)
        );
        activitiesSortedDescending.sort(
          (a, z) => new Date(z.start_date) - new Date(a.start_date)
        );

        return {
          ...ini,
          children: activitiesSortedAscending,
          descendedActivities: activitiesSortedDescending,
          start_date: activitiesSortedAscending[0].start_date,
        };
      });

      initiatives.sort((a, z) => new Date(a.start_date) - new Date(z.start_date));

      const updatedInitiatives = initiatives.map(ini => {
        const children = this.getActivitiesWithPosition(ini.children);
        const { start_date, startPoint, initiative_id } = children[0];
        const cloneChildren = [...children];
        const cloneChildrenDesc = [...ini.descendedActivities];

        cloneChildren.sort((a, z) => new Date(a.end_date) - new Date(z.end_date));

        const percent = this.findInitiativePercentage(cloneChildren, cloneChildrenDesc);
        const { end_date, endPoint } = cloneChildren[cloneChildren.length - 1];

        return {
          ...ini,
          percent,
          initiative_id,
          startPoint,
          endPoint,
          children,
          start_date,
          end_date,
          width: `${parseFloat(endPoint) - parseFloat(startPoint)}rem`,
          isInitiative: true,
        };
      });

      return updatedInitiatives.flat();
    },
    getInitiatives() {
      //* use regex.test for performance boost or String.indexOf
      // this.loading = true;
      return this.getSortedInitiatives.filter((object, index) => {
        return [...Object.values(object)].some(item =>
          typeof item === "string"
            ? item.toLowerCase().includes(this.searchText.trim().toLowerCase())
            : false
        );
      });
    },
    getPerDayWidth() {
      return (this.MONTH_IN_QUARTER_WIDTH / 30).toString().slice(0, 4); // 30 days
      // return this.MONTH_IN_QUARTER_WIDTH / 31; // 31 days
    },
  },
  methods: {
    getActivitiesWithPosition(activities) {
      const startDateOfGrid = dayjs(`01-01-${this.getYears.first}`);
      return activities.map(activity => {
        const startOffset = Number(
          dayjs(activity.start_date).diff(startDateOfGrid, "day", true).toFixed(0)
        );
        const offsetWidth = `${Number(
          (startOffset * this.getPerDayWidth).toFixed(0)
        )}rem`; // startPoint
        const durationInDays = dayjs(activity.end_date).diff(
          dayjs(activity.start_date),
          "day",
          true
        );
        // const durationInMonths = dayjs(activity.end_date).diff(
        //   dayjs(activity.start_date),
        //   "month"
        // );
        const width = `${durationInDays * this.getPerDayWidth}rem`;
        // const width = `${durationInMonths * this.MONTH_IN_QUARTER_WIDTH}rem`; // activity width
        const endPoint = `${Number(
          (parseFloat(offsetWidth) + parseFloat(width)).toFixed(0)
        )}rem`;

        // if (activity.initiative_id == 10) {
        //   console.log("10 => ", {
        //     start: activity.start_date,
        //     end: activity.end_date,
        //     width,
        //     durationInDays,
        //     gap: startOffset,
        //     startPoint: offsetWidth,
        //     startDateOfGrid,
        //     endPoint,
        //   });
        // }

        return {
          ...activity,
          name: activity.activity.name,
          id: activity.activity_id,
          startPoint: offsetWidth,
          endPoint,
          width,
        };
      });
    },
    findInitiativePercentage(activities, descActivities) {
      if (activities.length == 0) return 0;

      let totalDays = 1; // 1 is to avoid NaN or Infinity problem
      let completedDays = 0;
      let completedActivities = [];

      activities.forEach(activity => {
        const days = dayjs(activity.end_date).diff(activity.start_date, "day");

        totalDays += days;

        if (activity.status === Activity.COMPLETE) {
          completedDays = days + completedDays;
          completedActivities.push(activity);
        }
      });

      if (completedActivities.length == activities.length) {
        totalDays = 1;
        completedDays = 1;
      }

      return +((completedDays / totalDays) * 100).toFixed(2);
    },
    quarterStyle(width) {
      return {
        position: "absolute",
        width: this.getGridWidth.cellWidth,
        left: width,
        "--height": `${this.data.length * 61}px`,
      };
    },
  },
  components: {
    Tree,
    NodeTree,
  },
};
</script>

<style>
.gantt,
.gantt-container {
  height: 100%;
}

.gantt-wrapper {
  width: 100%;
  overflow-x: auto;
  /* overflow-y: auto; */
  padding-bottom: 1rem;
  position: relative;
  height: 100%;
  scroll-behavior: smooth;
}

.gantt-wrapper .n-scrollbar-content {
  width: var(--wrapper-width);
}

.gantt-header {
  height: 12.45rem;
  background: #fff;
  z-index: 9;
  /* padding-bottom: 1.5rem; */
}

.gantt-search {
  padding: 24px 32px;
  position: sticky;
  top: 0;
  z-index: 9;
  background: #f5f5f5;
  justify-content: center;
  /* margin-bottom: 1rem !important; */
}

.gantt-grid {
  position: relative;
  /* padding-top: 2.6rem; */
}

.gantt-grid::after {
  content: "";
  background: #e9e5da4a;
  position: absolute;
  right: 0;
  width: 10rem;
  height: 10rem;
  top: -10rem;
  bottom: 0;
  height: calc(100% + 15rem);
}

.quarters,
.years {
  width: inherit;
}

.quarter,
.year {
  text-align: center;
  color: #969da5;
}

.year,
.quarter-value {
  /* border-right: 1px solid #e5e5e5; */
  color: var(--n-text-color);
}

.quarter {
  height: 100%;
  height: 5rem;
}

.quarter > span {
  font-size: 0.8rem;
}

.quarter:after {
  content: "";
  width: 1px;
  height: var(--height, 0);
  background: #e5e5e570;
  position: absolute;
  right: -0.1rem;
  top: 0;
}

.quarter-value {
  font-weight: bold;
}

.sticky {
  position: sticky;
  top: 0;
  width: inherit;
}

.n-input .n-input__prefix {
  margin-right: 1.3rem;
}

/*  */
.node-tree.is-parent:not(.is-bar) {
  border-bottom: 1px solid #e5e5e5;
}
</style>
