import { createRouter, createWebHashHistory } from "vue-router";
import { HomePage, AboutPage, AuthPage } from "@pages";
import { Route } from "@config/Constants";
import store from "@store";

const CalendarPage = () => import("../pages/Calendar/CalendarPage.vue");
const ActivitiesPage = () => import("../pages/Activities/ActivitiesPage.vue");
const CreateInitiativePage = () =>
  import("../pages/Initiatives/CreateInitiativePage.vue");
const ListInitiativesPage = () => import("../pages/Initiatives/ListInitiativesPage.vue");
const DocumentsPage = () => import("../pages/Documents/DocumentsPage.vue");

const routes = [
  {
    path: Route.HOMEPAGE.Path,
    name: Route.HOMEPAGE.Name,
    component: ListInitiativesPage,
    meta: {
      title: Route.REVIEWSPAGE.Title,
    },
  },
  {
    path: Route.CREATEINITIATIVE.Path,
    name: Route.CREATEINITIATIVE.Name,
    component: CreateInitiativePage,
    meta: {
      title: Route.CREATEINITIATIVE.Title,
    },
  },
  {
    path: Route.REVIEWSPAGE.Path,
    name: Route.REVIEWSPAGE.Name,
    component: ListInitiativesPage,
    meta: {
      title: Route.REVIEWSPAGE.Title,
    },
  },
  {
    path: Route.SEARCHPAGE.Path,
    name: Route.SEARCHPAGE.Name,
    component: HomePage,
    meta: {
      title: Route.SEARCHPAGE.Title,
    },
  },
  {
    path: Route.ACTIVITIESPAGE.Path,
    name: Route.ACTIVITIESPAGE.Name,
    component: ActivitiesPage,
    meta: {
      title: Route.ACTIVITIESPAGE.Title,
    },
  },
  {
    path: Route.CALENDARPAGE.Path,
    name: Route.CALENDARPAGE.Name,
    component: CalendarPage,
    meta: {
      title: Route.CALENDARPAGE.Title,
    },
  },
  {
    path: Route.MESSAGESPAGE.Path,
    name: Route.MESSAGESPAGE.Name,
    component: CalendarPage,
    meta: {
      title: Route.MESSAGESPAGE.Title,
    },
  },
  {
    path: Route.DOCUMENTSPAGE.Path,
    name: Route.DOCUMENTSPAGE.Name,
    component: DocumentsPage,
    meta: {
      title: Route.DOCUMENTSPAGE.Title,
    },
  },
  {
    path: Route.AUTHPAGE.Path,
    name: Route.AUTHPAGE.Name,
    component: AuthPage,
  },
  {
    path: Route.NOTFOUNDPAGE.Path,
    name: Route.NOTFOUNDPAGE.Name,
    component: AboutPage,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const page = to.name;
  const publicPages = [Route.AUTHPAGE.Name];
  const publicPage = publicPages.includes(page);
  // const loggedIn = store.getters["user/isLoggedIn"];
  const loggedIn = store.getters.isLoggedIn;

  if (loggedIn && publicPage) next({ replace: true, name: from.name });
  else if (!loggedIn && !publicPage) next({ name: Route.AUTHPAGE.Name });
  else {
    const EMPTY = "";
    store.commit("SET_SEARCH", EMPTY);
    next();
  }
});

export default router;
