import { Auth } from "aws-amplify";
import { createStore } from "vuex";
// import { userStore as user } from "./modules/user";
import {
  Initial,
  Events,
  INITIATIVE_CARD_ROW_LABELS,
  InitiativeStates,
  Pagination,
} from "@config/Constants";
import { trycatch, TokenService, dateFormat } from "@utils";
import { UserService } from "@services/User.service";
import { InitiativesService } from "@services/Initiatives.service";
import { Route } from "@config/Constants";
import router from "../router";
import { nanoid } from "nanoid";

const isLoading = true;

const store = createStore({
  state: {
    error: null,
    user: Initial.state.user,
    isAppLoading: false,

    // Search
    searchField: "",

    // User Initiatives
    fetchedInitiatives: false,
    initiated: {
      totalSavings: 0,
      initiatives: [],
    },
    awaiting: {
      totalSavings: 0,
      initiatives: [],
    },
    approved: {
      totalSavings: 0,
      initiatives: [],
    },
    draft: {
      totalSavings: 0,
      initiatives: [],
    },
    buoExpenseCategories: [],

    // User Activities
    activities: [],
    groupedActivities: [],
    groupedActivitiesYears: [],
    // systemUsers: [],
    isUserLoggedIn: false,
    initiatveCreateOrUpdateData: {
      overview: {},
      expenseCategories: [],
      activities: [],
      projectCosts: [],
      documents: [],
    },
    selectedExpenseAccounts: [],
    selectedExpenseCategoryIds: {},
    selectedInitiativeCostCenters: [],
  },
  getters: {
    isLoggedIn: state => !!state.user,
    getUserMeta: state => ({
      buoCt: state.user?.buoCt,
      eloEc: state.user?.eloEc,
      type: state.user?.userType,
      owner: state.user?.name,
    }),
    getInitiated: state => {
      const sum = state.initiated.totalSavings;
      let initiated = state.initiated.initiatives;
      const search = state.searchField.trim().toLowerCase();

      if (search) {
        initiated = initiated.filter(initiative =>
          initiative.short_name.toLowerCase().includes(search)
        );
      }

      const data = initiated.map(ini => {
        const [activity] = ini.initiativeActivities;

        return {
          id: ini.initiative_id,
          title: ini.short_name,
          auto_approve_stakeholders: ini.auto_approve_stakeholders,
          savings: ini.input_savings,
          date: dateFormat(activity?.start_date),
          due: dateFormat(activity?.end_date),
          rows: [
            [
              { label: INITIATIVE_CARD_ROW_LABELS.ID, value: ini.initiative_uuid },
              {
                label: INITIATIVE_CARD_ROW_LABELS.SAVINGS_TYPE,
                value: ini.business_expense_type.name,
              },
              {
                label: INITIATIVE_CARD_ROW_LABELS.CATEGORY,
                value: ini.initiative_category.name,
              },
            ],
            [
              { label: INITIATIVE_CARD_ROW_LABELS.OWNER, value: ini.waypoint_user.name },
              {
                label: INITIATIVE_CARD_ROW_LABELS.DEPARTMENT,
                value: getDepartment(
                  ini.waypoint_user?.departments,
                  ini.waypoint_user?.cost_centers
                ),
              },
            ],
          ],
          raw: ini,
        };
      });

      return {
        data,
        sum,
      };
    },
    getAwaiting: state => {
      const sum = state.awaiting.totalSavings;
      let awaiting = state.awaiting.initiatives;
      const search = state.searchField.trim().toLowerCase();

      if (search) {
        awaiting = awaiting.filter(initiative =>
          initiative.short_name.toLowerCase().includes(search)
        );
      }

      const data = awaiting.map(awa => {
        return {
          id: awa.initiative_id,
          title: awa.short_name,
          auto_approve_stakeholders: awa.auto_approve_stakeholders,
          savings: awa.input_savings,
          date: dateFormat(awa.initiativeActivities[0]?.start_date),
          due: dateFormat(awa.initiativeActivities[0]?.end_date),
          rows: [
            [
              { label: INITIATIVE_CARD_ROW_LABELS.ID, value: awa.initiative_uuid },
              {
                label: INITIATIVE_CARD_ROW_LABELS.SAVINGS_TYPE,
                value: awa.business_expense_type.name,
              },
              {
                label: INITIATIVE_CARD_ROW_LABELS.CATEGORY,
                value: awa.initiative_category.name,
              },
            ],
            [
              { label: INITIATIVE_CARD_ROW_LABELS.OWNER, value: awa.waypoint_user.name },
              {
                label: INITIATIVE_CARD_ROW_LABELS.DEPARTMENT,
                value: getDepartment(
                  awa.waypoint_user?.departments,
                  awa.waypoint_user?.cost_centers
                ),
              },
            ],
          ],
          raw: awa,
        };
      });

      return {
        data,
        sum,
      };
    },
    getApproved: state => {
      const sum = state.approved.totalSavings;
      let approved = state.approved.initiatives;
      const search = state.searchField.trim().toLowerCase();

      if (search) {
        approved = approved.filter(initiative =>
          initiative.short_name.toLowerCase().includes(search)
        );
      }

      const data = approved.map(appr => {
        return {
          id: appr.initiative_id,
          title: appr.short_name,
          auto_approve_stakeholders: appr.auto_approve_stakeholders,
          savings: appr.input_savings,
          date: dateFormat(appr.initiativeActivities[0]?.start_date),
          due: dateFormat(appr.initiativeActivities[0]?.end_date),
          rows: [
            [
              { label: INITIATIVE_CARD_ROW_LABELS.ID, value: appr.initiative_uuid },
              {
                label: INITIATIVE_CARD_ROW_LABELS.SAVINGS_TYPE,
                value: appr.business_expense_type.name,
              },
              {
                label: INITIATIVE_CARD_ROW_LABELS.CATEGORY,
                value: appr.initiative_category.name,
              },
            ],
            [
              { label: INITIATIVE_CARD_ROW_LABELS.OWNER, value: appr.waypoint_user.name },
              {
                label: INITIATIVE_CARD_ROW_LABELS.DEPARTMENT,
                value: getDepartment(
                  appr.waypoint_user?.departments,
                  appr.waypoint_user?.cost_centers
                ),
              },
            ],
          ],
          raw: appr,
        };
      });

      return {
        data,
        sum,
      };
    },
    getDrafts: state => {
      const sum = state.draft.totalSavings;
      let draft = state.draft.initiatives;
      const search = state.searchField.trim().toLowerCase();

      if (search) {
        draft = draft.filter(initiative =>
          initiative.short_name.toLowerCase().includes(search)
        );
      }

      const data = draft.map(dr => {
        return {
          id: dr.intiative_id,
          title: dr.short_name,
          auto_approve_stakeholders: dr.auto_approve_stakeholders,
          savings: dr.input_savings,
          date: dateFormat(dr.initiativeActivities[0]?.start_date) ?? "[]",
          due: dateFormat(dr.initiativeActivities[0]?.end_date) ?? "[]",
          rows: [
            [
              { label: INITIATIVE_CARD_ROW_LABELS.ID, value: dr.initiative_uuid },
              {
                label: INITIATIVE_CARD_ROW_LABELS.SAVINGS_TYPE,
                value: dr.initiative_type.type_name,
              },
              {
                label: INITIATIVE_CARD_ROW_LABELS.CATEGORY,
                value: dr.initiative_category.name,
              },
            ],
            [
              { label: INITIATIVE_CARD_ROW_LABELS.OWNER, value: dr.waypoint_user.name },
              {
                label: INITIATIVE_CARD_ROW_LABELS.DEPARTMENT,
                value: getDepartment(
                  dr.waypoint_user?.departments,
                  dr.waypoint_user?.cost_centers
                ),
              },
            ],
          ],
          raw: dr,
        };
      });

      return {
        data,
        sum,
      };
    },
    getReviewsAndApprovals: (state, getters) => {
      return [
        {
          title: "Programs Awaiting Your Approval",
          value: getters.getAwaiting.sum,
          bg: "var(--chakra-colors-warning-500)",
          color: "warning.500",
          cards: getters.getAwaiting.data,
          event: Events.AWAITING,
          state: InitiativeStates.AWAITING,
        },
        {
          title: "Programs Initiated by You",
          value: getters.getInitiated.sum,
          bg: "var(--chakra-colors-purple-500)",
          color: "purple.500",
          cards: getters.getInitiated.data,
          event: Events.INITIATED,
          state: InitiativeStates.INITIATED,
        },
        {
          title: "Programs Approved by You",
          value: getters.getApproved.sum,
          bg: "var(--chakra-colors-success-500)",
          color: "success.500",
          cards: getters.getApproved.data,
          event: Events.APPROVED,
          state: InitiativeStates.APPROVED,
        },
        {
          title: "Draft Initiatives",
          value: getters.getDrafts.sum,
          bg: "var(--chakra-colors-gray-300)",
          color: "gray.500",
          cards: getters.getDrafts.data,
          event: undefined,
          state: InitiativeStates.DRAFT,
        },
      ];
    },
    getActivities: state => {
      let activities = state.activities;
      const search = state.searchField.trim().toLowerCase();

      if (search) {
        activities = activities.filter(activity =>
          activity.initiative?.short_name.toLowerCase().includes(search)
        );
      }

      return activities;
    },
  },
  mutations: {
    SET_INITIATIVE_CREATE_OR_UPDATE(state, { field, data }) {
      state.initiatveCreateOrUpdateData[field] = data;
    },
    SET_SELECTED_EXPENSE_CATEGORY_IDS(state, { costCenterId, categoryId }) {
      const costCenterCategoryIds = state.selectedExpenseCategoryIds[costCenterId] ?? [];
      state.selectedExpenseCategoryIds[costCenterId] = [
        ...costCenterCategoryIds,
        categoryId,
      ];
    },
    SET_IS_LOGGED_IN(state, payload) {
      state.isUserLoggedIn = payload;
    },
    SET_LOADING(state, status) {
      state.isAppLoading = status;
    },
    SET_USER(state, data) {
      state.user = data;
    },
    RESET_STATE(state) {
      state.error = null;
      state.user = null;
      state.isAppLoading = false;
      state.fetchedInitiatives = false;
      state.isUserLoggedIn = false;
      state.initiated = {
        totalSavings: 0,
        initiatives: [],
      };
      state.awaiting = {
        totalSavings: 0,
        initiatives: [],
      };
      state.approved = {
        totalSavings: 0,
        initiatives: [],
      };
      state.draft = {
        totalSavings: 0,
        initiatives: [],
      };
      state.activities = [];
      state.groupedActivities = [];
      state.groupedActivitiesYears = [];
      state.selectedExpenseAccounts = [];
      state.selectedExpenseCategoryIds = {};
      state.selectedInitiativeCostCenters = [];

      router.push({ name: Route.AUTHPAGE.Name });
    },
    RESET_INITIATIVE_STATE(state) {
      state.selectedExpenseAccounts = [];
      state.selectedExpenseCategoryIds = {};
      state.selectedInitiativeCostCenters = [];
      state.initiatveCreateOrUpdateData = {
        overview: {},
        expenseCategories: [],
        activities: [],
        projectCosts: [],
        documents: [],
      };
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    SET_GENERAL_ERROR(state) {
      state.error = {
        type: "error",
        message: "Whoops... Something unexpected happened. Please try again later.",
      };
    },
    SET_INITIATED(state, payload) {
      state.fetchedInitiatives = true;
      state.initiated = prepareInitiatives(state.initiated.initiatives, payload);
    },
    SET_AWAITING(state, payload) {
      state.fetchedInitiatives = true;
      state.awaiting = prepareInitiatives(state.awaiting.initiatives, payload);
    },
    SET_APPROVED(state, payload) {
      state.fetchedInitiatives = true;
      state.approved = prepareInitiatives(state.approved.initiatives, payload);
    },
    SET_DRAFT(state, payload) {
      state.fetchedInitiatives = true;
      state.draft = prepareInitiatives(state.draft.initiatives, payload);
    },
    SET_GROUPED_ACTIVITES(state, payload) {
      state.groupedActivities = payload.data;
      state.groupedActivitiesYears = payload.years;
    },
    SET_SEARCH(state, payload) {
      state.searchField = payload.trim();
    },
    SET_ACTIVITIES(state, payload) {
      if (state.activities.length > 0) return;

      state.activities = payload;
    },
    SET_BUO_EXPENSE_CATEGORIES(state, payload) {
      state.buoExpenseCategories = payload;
    },
    SET_SELECTED_EXPENSE_ACCOUNTS(state, { account, REMOVED }) {
      state.selectedExpenseAccounts = state.selectedExpenseAccounts.filter(acc => {
        const isAnotherCostCenterAccount =
          acc.costCenterId != account.costCenterId &&
          acc.expense_account_id == account.expense_account_id;
        const isCostCenterAnotherAccount =
          acc.expense_account_id != account.expense_account_id;

        return isAnotherCostCenterAccount || isCostCenterAnotherAccount;
      });
      if (REMOVED) return; // REMOVED value makes filter work as remove or update action.

      state.selectedExpenseAccounts.push(account);
    },
    SET_SELECTED_INITIATIVE_COST_CENTERS(state, payload) {
      state.selectedInitiativeCostCenters.push(payload);
    },
    CLEAR_SELECTED_EXPENSE_DATA(state) {
      state.selectedExpenseAccounts = [];
      state.selectedExpenseCategoryIds = {};
      state.selectedInitiativeCostCenters = [];
    },
    // SET_SYSTEM_USERS(state, payload) {
    //   state.systemUsers = payload;
    // },
  },
  actions: {
    async FetchUser({ commit, dispatch }, username) {
      commit("SET_LOADING", isLoading);

      const userPromise = UserService.fetchUser({ username });
      const [error, userObj] = await trycatch(userPromise);

      if (error || !userObj?.data) {
        // await dispatch("Signout"); // uncomment this line

        if (!userObj?.data) {
          commit("SET_ERROR", {
            type: "error",
            message: "User not found. Logging out...",
            meta: "Data is missing!",
          });
          TokenService.clearStorage();
          commit("RESET_STATE");
          return;
        }
      } else {
        const { data } = userObj;

        commit("SET_USER", data);

        const { waypoint_user_id } = data;

        const userTypePromise = UserService.fetchUserType(waypoint_user_id);
        const BUOcostCentersPromise =
          UserService.fetchBUOUserCostCenters(waypoint_user_id);
        const BUOExpenseCategoriesPromise =
          UserService.fetchBUOUserExpenseCategories(waypoint_user_id);
        const ELOExpenseCategoriesPromise =
          UserService.fetchELOUserExpenseCategories(waypoint_user_id);
        const allPromises = Promise.all([
          userTypePromise,
          BUOcostCentersPromise,
          BUOExpenseCategoriesPromise,
          ELOExpenseCategoriesPromise,
        ]);

        const [error, response] = await trycatch(allPromises);

        if (error) {
          commit("SET_ERROR", {
            type: "error",
            message: "Error while fetching user data.",
            meta: "Try to re-login.",
          });

          return;
        }

        const [
          { data: userType },
          { data: BUOCostCenters },
          { data: BUOExpenseCategories },
          { data: ELOExpenseCategories },
        ] = response;

        commit("SET_BUO_EXPENSE_CATEGORIES", BUOExpenseCategories);

        const user = {
          ...data,
          userType,
          buoCt: BUOCostCenters,
          buoEc: BUOExpenseCategories.map(cat => cat.expense_category_id),
          eloEc: ELOExpenseCategories,
        };

        TokenService.setUser({ cookieUser: { ...data, userType }, localUser: user });
        commit("SET_USER", user);
      }

      commit("SET_LOADING", !isLoading);
    },
    async Signout({ commit }) {
      console.log("signing out...");
      commit("SET_LOADING", true);
      const signoutPromise = Auth.signOut({ global: true });
      const [error, signout] = await trycatch(signoutPromise);

      if (error) {
      }

      TokenService.clearStorage();
      commit("RESET_STATE");
    },
    async FetchInitiatives({ commit, state }, refetch = false) {
      if (state.fetchedInitiatives && !refetch) return;

      commit("SET_LOADING", isLoading);

      const { waypoint_user_id: user_id } = TokenService.getUser();
      const queryParams = {
        limit: Pagination.limit,
        offset: 0,
      };

      const initiatedPromise = InitiativesService.fetchUserInitiatives({
        ...queryParams,
        user_id,
        event: Events.INITIATED,
      });

      const awaitingPromise = InitiativesService.fetchUserInitiatives({
        ...queryParams,
        user_id,
        event: Events.AWAITING,
      });

      const approvalPromise = InitiativesService.fetchUserInitiatives({
        ...queryParams,
        user_id,
        event: Events.APPROVED,
      });

      const draftPromise = InitiativesService.fetchDraftInitiatives({
        ...queryParams,
        manager_id: user_id,
      });

      const allPromises = Promise.all([
        initiatedPromise,
        awaitingPromise,
        approvalPromise,
        draftPromise,
      ]);

      const [error, response] = await trycatch(allPromises);

      if (error || !response || response.status == 404) {
        const Error = {
          type: "error",
          message: "Error! We could not fetch user initiatives.",
          meta: "Try to re-login the app.",
        };
        TokenService.clearStorage();
        commit("RESET_STATE");
        commit("SET_ERROR", Error);
        console.log("Error while fetching initiatives", error);
      }

      const [
        { data: initiatedData },
        { data: awaitingData },
        { data: approvalData },
        { data: draftData },
      ] = response;

      commit("SET_INITIATED", { ...initiatedData, refetch });
      commit("SET_AWAITING", { ...awaitingData, refetch });
      commit("SET_APPROVED", { ...approvalData, refetch });
      commit("SET_DRAFT", { ...draftData, refetch });

      commit("SET_LOADING", !isLoading);
    },
    async FetchInitiative({ commit }, initiative_id) {
      commit("SET_LOADING", isLoading);

      const initiativePromise = InitiativesService.fetchInitiative(initiative_id);
      const [error, response] = await trycatch(initiativePromise);
      if (error || !response?.data) {
        const Error = {
          type: "error",
          message: "Error occurred while fetching initiative.",
          meta: "Try to reload the page",
        };
        commit("SET_LOADING", !isLoading);
        commit("SET_ERROR", Error);
      }

      const { data: initiative } = response;

      // Prepare step 1 data
      const costCentreIds = initiative.initiative_cost_centers.map(
        cost_center => cost_center.cost_center_id
      );

      const costCentres = initiative.initiative_cost_centers.map(cost_center => ({
        cost_center_id: cost_center.cost_center_id,
        cost_centers_hierarchy_id: cost_center.cost_center.id,
        id: cost_center.cost_center_id,
        isLeaf: Boolean(cost_center.cost_center_id),
        key: Boolean(cost_center.cost_center_id)
          ? cost_center.cost_center_id
          : cost_center.cost_centers_hierarchy_id,
        label: cost_center.cost_center.name,
        level_name: cost_center.cost_center.name,
        parent_id: cost_center.cost_center.parent_id,
      }));

      const initiativeStep1 = {
        name: initiative.short_name,
        description: initiative.description,
        selectedDelegate: {
          value: String(initiative.delegate.waypoint_user_id),
          label: initiative.delegate.name,
        },
        selectedExpenseType: {
          value: String(initiative.business_expense_type.business_expense_type_id),
          label: initiative.business_expense_type.name,
        },
        selectedInitiativeType: {
          value: String(initiative.initiative_type.initiative_type_id),
          label: initiative.initiative_type.type_name,
        },
        selectedCategory: {
          value: String(initiative.initiative_category.initiative_category_id),
          label: initiative.initiative_category.name,
        },
        selectedCostCenterIds: costCentreIds,
        selectedCostCenters: costCentres,
      };
      const initiativeStep2 = initiative.initiative_expense_accounts.map(iea => ({
        expense_account_id: iea.expense_account_id,
        value: iea.input_savings,
        option: iea.savings_type,
        original_savings: iea.original_savings,
        cost_center_id: iea.ieacc?.icc.cost_center_id,
        costCenterId: iea.ieacc?.icc.cost_center_id,
        initiative_cost_center_id: iea.ieacc?.initiative_cost_center_id,
        cost_center_expense_category_id: iea.cost_center_expense_category_id,
        expenseCategory: iea.ieacc?.cat_name,
        check_value: {
          expense_category_id: iea.ieacc?.cat_name.expense_category_id,
        },
        accountName: iea.expense_account.name,
        raw: iea,
      }));
      const initiativeStep3 = initiative.initiativeActivities.map(item => ({
        id: item.activity_id,
        name: item.activity?.name,
        startDate: new Date(item.start_date).getTime(),
        endDate: new Date(item.end_date).getTime(),
        option: item.activity_type,
        onlyStartDate: false,
        activityEdit: false,
        realizationCheck: Boolean(item.savings_realization_check),
        selectedResponsibilityOrOwner: {
          label: item.waypoint_user.name,
          value: `${item.waypoint_user.waypoint_user_id}`,
        },
      }));
      const initiativeStep4 = initiative.initiative_project_costs
        .map(item => item.cost_info)
        .flat();
      const initiativeStep5 = initiative.initiative_documents.map(item => ({
        id: nanoid(),
        name: item.doc_name,
        option: item.document_type_id,
        startDate: "2022-08-19T10:23:29.474Z",
        onlyStartDate: true,
        activityEdit: false,
        realizationCheck: false,
        selectedResponsibilityOrOwner: item.allow_stakeholders[0],
      }));

      commit("SET_INITIATIVE_CREATE_OR_UPDATE", {
        field: "overview",
        data: initiativeStep1,
      });
      commit("SET_INITIATIVE_CREATE_OR_UPDATE", {
        field: "expenseCategories",
        data: initiativeStep2,
      });
      initiativeStep2.map(account =>
        commit("SET_SELECTED_EXPENSE_ACCOUNTS", { account })
      );

      costCentreIds.map(cc => {
        const expenseAccount = initiativeStep2.find(
          expenseAccount => expenseAccount.cost_center_id == cc
        );
        if (expenseAccount) {
          commit("SET_SELECTED_EXPENSE_CATEGORY_IDS", {
            costCenterId: cc,
            categoryId: expenseAccount.expenseCategory.expense_category_id,
          });
        }
      });
      commit("SET_INITIATIVE_CREATE_OR_UPDATE", {
        field: "activities",
        data: initiativeStep3,
      });
      commit("SET_INITIATIVE_CREATE_OR_UPDATE", {
        field: "projectCosts",
        data: initiativeStep4,
      });
      commit("SET_INITIATIVE_CREATE_OR_UPDATE", {
        field: "documents",
        data: initiativeStep5,
      });
      commit("SET_LOADING", !isLoading);
    },
    async LoadMoreInitiatives({ commit, state }, { event, initiativeState }) {
      commit("SET_LOADING", isLoading);

      const { waypoint_user_id: user_id } = TokenService.getUser();
      const queryParams = {
        limit: Pagination.limit,
        pageNumber: Math.ceil(state[initiativeState].initiatives?.length / 5) + 2,
      };
      const initiativesPromise = event
        ? InitiativesService.fetchUserInitiatives({
          ...queryParams,
          user_id,
          event,
        })
        : InitiativesService.fetchDraftInitiatives({
          ...queryParams,
          manager_id: user_id,
        });
      const [error, response] = await trycatch(initiativesPromise);

      if (error || !response?.data) {
        const Error = {
          type: "error",
          message: "Error occurred while fetching more initiatives.",
          meta: "Try to reload the page",
        };
        commit("SET_LOADING", !isLoading);
        commit("SET_ERROR", Error);
      }

      const { data } = response;
      const originalInitiatives = JSON.parse(JSON.stringify(data.initiatives));

      switch (initiativeState) {
        case InitiativeStates.INITIATED:
          commit("SET_INITIATED", data);
          break;
        case InitiativeStates.AWAITING:
          commit("SET_AWAITING", data);
          break;
        case InitiativeStates.APPROVED:
          commit("SET_APPROVED", data);
          break;

        default:
          commit("SET_DRAFT", data);
          break;
      }

      commit("SET_LOADING", !isLoading);
      return originalInitiatives;
    },
    async DeleteInitiative({ commit }, id) {
      const deletePromise = InitiativesService.deleteInitiative(id);
      return await trycatch(deletePromise);
    },
  },
  // modules: {
  //   user,
  // },
});

/**
 * Prepare Initiatives Data
 * @param {*} stateInitiatives stateInitiatives
 * @param {*} payload
 */
const prepareInitiatives = (stateInitiatives, payload) => {
  let { initiatives, totalSavings = 0, refetch = false } = payload;
  if (!Array.isArray(initiatives)) initiatives = [];

  refetch ? (initiatives = initiatives) : initiatives.push(...stateInitiatives);
  return {
    initiatives,
    totalSavings,
  };
};

/**
 * Get departments
 * @param {*} departments departments
 */
const getDepartment = (departments, costCentres) => {
  if (!Array.isArray(departments)) departments = [];
  if (!Array.isArray(costCentres)) costCentres = [];

  if (departments.length || costCentres.length) {
    const data = departments.length ? departments : costCentres;
    const clonedData = [...data];

    return clonedData.length > 1 ? "Multiple" : clonedData.shift()?.name;
  }
  return "-";
};

export default store;
