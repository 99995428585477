<template>
  <NConfigProvider :theme-overrides="waypointTheme" :inline-theme-disabled="true">
    <NNotificationProvider>
      <NDialogProvider>
        <NLayout has-sider>
          <Sidebar v-if="isLoggedIn" />
          <Pages :isLoggedIn="isLoggedIn" />
        </NLayout>
      </NDialogProvider>
    </NNotificationProvider>
  </NConfigProvider>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import { Auth, Hub } from "aws-amplify";
import { Sidebar, Pages } from "@components/Common";
import { waypointTheme } from "@config/Theme";
import { Route, WAYPOINT_USER, AWS_AUTH_EVENT } from "@config/Constants";
import { TokenService, trycatch } from "@utils";
// import { UserService } from "@services/User.service.js";

export default {
  async created() {
    const user = TokenService.getUser();
    const isSetLocalStorageUser = TokenService.isSetLocalStorageUser();
    if (!isSetLocalStorageUser) await this.getAuthUser();
    else this.SET_USER(user);
  },
  mounted() {
    Hub.listen("auth", async ({ payload }) => {
      if (payload.event === AWS_AUTH_EVENT.SIGNIN) {
        const { data } = payload;
        const { username } = data;

        TokenService.setToken(data.signInUserSession.getAccessToken().getJwtToken());

        this.SET_USER({ username });
        await this.FetchUser(username);
        this.$router.push({ name: Route.HOMEPAGE.Name });
      } else if (payload.event === AWS_AUTH_EVENT.SIGNOUT) {
        this.RESET_STATE();
      }
    });
  },
  data: () => ({
    waypointTheme,
  }),
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["isLoggedIn"]),
  },
  methods: {
    ...mapMutations(["SET_USER", "RESET_STATE"]),
    ...mapActions(["FetchUser"]),
    async getAuthUser() {
      const [error, userObj] = await trycatch(Auth.currentAuthenticatedUser());

      if (error) {
        this.RESET_STATE();
        return;
      }
      TokenService.setToken(userObj.signInUserSession.getAccessToken().getJwtToken());

      this.SET_USER({ username: userObj.username });
      this.FetchUser(userObj.username);
    },
  },
  components: {
    Sidebar,
    Pages,
  },
};
</script>

<style>
html {
  /* font-size: 62.5%; */
  font-size: 56.25%;
  /* height: 95vh; */
  --n-text-color: #050c2b;
  --n-item-text-color: #050c2b;
  --n-error-color: #eb5757;
  --n-success-color: #1cb79b;
  --n-gray-color: #969da5;
  --n-disabled-gray-color: #dadee3;
  --n-light-blue-color: #f1f5fa;
  --n-blue-color: #1264ce;
  --n-white-lite: #f9f9f9;
  --n-light-blue-color-2: #e3ebf6;
}

body {
  font-family: "Poppins", sans-serif;
  color: var(--n-text-color, #111);
  font-size: 1.4rem;
}

.page,
.n-layout {
  height: 100%;
  margin: 0;
}
/* Allows for static positioning of sidebar */
/* .n-layout, */
.n-layout.n-layout--static-positioned > .n-layout-scroll-container {
  overflow: initial !important;
}

.pages.DocumentsPage .n-layout-content,
.pages.CalendarPage .n-layout-content,
.pages.ActivitiesPage .n-layout-content {
  background: #fff;
  padding: 0;
}

.pages.CreateInitiativePage .n-layout-content {
  padding: 0;
}

.sticky-header {
  position: sticky;
  top: -1px;
  background: #fff;
  z-index: 999;
}

.box-shadow-bottom[stuck] {
  box-shadow: 0 0 16px black;
}

.box-shadow-bottom {
  box-shadow: 0 1px 6px 0px #4444441d;
}

.n-scrollbar-rail {
  z-index: 999999;
}

.vertical-scroll {
  max-height: calc(100vh - 13rem);
  padding-right: 0.5rem;
}

.spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e9e5da;
  z-index: 10;
  font-size: 3rem;
}

.flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex !important;
  flex-direction: column !important;
}

.flex-grow {
  flex-grow: 1;
}

.gray-text {
  color: var(--n-gray-color, #969da5);
}

.success-text {
  color: var(--n-success-color, #1cb79b);
}

.dark-text {
  color: var(--n-text-color, #050c2b);
}

.blue-text {
  color: var(--n-blue-color, #1264ce);
}

.warning-text {
  color: var(--n-error-color, #eb5757);
}

.h1,
.t24 {
  font-size: 2.4rem !important;
  line-height: 3.6rem !important;
}

.h2,
.t16 {
  font-size: 1.6rem !important;
  line-height: 2.4rem !important;
}

.h3,
.t14 {
  font-size: 1.4rem !important;
  line-height: 2.1rem !important;
}

.h4,
.t12 {
  font-size: 1.2rem !important;
  line-height: 1.9rem !important;
}

.no-margin {
  margin: 0 !important;
}

.bold500 {
  font-weight: 500 !important;
}

.bold600 {
  font-weight: 600 !important;
}

.v-binder-follower-content {
  min-width: 20rem;
}

.n-base-selection .n-base-selection-label {
  height: 5rem;
}

.capitalize {
  text-transform: capitalize;
}

.pt-16 {
  padding-top: 1.6rem;
}

.pr-16 {
  padding-right: 1.6rem;
}

.pb-16 {
  padding-bottom: 1.6rem;
}

.pl-16 {
  padding-left: 1.6rem;
}

.p-16 {
  padding: 1.6rem;
}

.pt-32 {
  padding-top: 3.2rem;
}

.pr-32 {
  padding-right: 3.2rem;
}

.pb-32 {
  padding-bottom: 3.2rem;
}

.pl-32 {
  padding-left: 3.2rem;
}

.p-32 {
  padding: 3.2rem;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.disabled {
  background: #eee !important;
}

.n-form-item-blank .n-radio {
  margin-right: 4rem;
}

.n-tag {
  border-radius: 100px;
  padding: 1rem 0;
  padding-left: 2rem;
  padding-right: 2rem;
  background: #e3ebf6;
}

.n-tag__content,
.n-tag__close {
  color: #467fd6;
  font-weight: bold;
}

.n-tag__close {
  margin-left: 1rem;
}

.n-tag.info,
.n-tag.warning {
  background: var(--n-color);
}

.n-data-table-tr.highlight-success .n-tag.success .n-tag__content,
.n-data-table-tr.highlight-error .n-tag.error .n-tag__content,
.n-tag.info .n-tag__content,
.n-tag.warning .n-tag__content {
  color: var(--n-text-color);
}

.n-tag.error {
  background: var(--n-error-color);
}

.n-tag.success {
  background: var(--n-success-color);
}

.n-tag.success .n-tag__content,
.n-tag.error .n-tag__content {
  color: #fff;
}

.vld-overlay.is-active {
  z-index: 999999 !important;
}

.n-dialog .n-button {
  min-width: 22rem;
  border-radius: 5px;
}

.custom.n-input input.n-input__input-el {
  height: 100%;
}

.n-card.n-modal:not(.file-modal) {
  padding: 0;
}

.n-card-header {
  padding-bottom: 0 !important;
}

.n-card-header .title {
  font-size: 2.5rem;
}

.n-card__footer {
  display: flex;
  justify-content: flex-end;
}

.n-data-table-tr.highlight-error td,
.n-data-table .n-data-table-tr.highlight-error:hover td,
.n-data-table-tr.highlight-success td,
.n-data-table .n-data-table-tr.highlight-success:hover td {
  color: #fff;
  font-weight: bold;
  background: var(--n-error-color) !important;
}

.n-data-table-tr.highlight-success td,
.n-data-table .n-data-table-tr.highlight-success:hover td {
  background: var(--n-success-color) !important;
}

.n-data-table-tr.highlight-error .n-tag.error,
.n-data-table-tr.highlight-success .n-tag.success {
  background: #fff !important;
}

.n-button.n-button--disabled {
  opacity: 0.65;
  font-weight: bold;
}

.n-dialog.n-modal {
  width: 70rem;
}

.n-dialog.danger-modal .n-dialog__title,
.n-dialog.danger-modal .n-dialog__action .n-button:last-child {
  --n-color: var(--n-error-color);
  --n-color-hover: var(--n-error-color);
  --n-color-pressed: var(--n-error-color);
  --n-color-focus: var(--n-error-color);
  --n-ripple-color: var(--n-error-color);
  --n-icon-color: var(--n-error-color);
}

.n-dialog .n-dialog__content {
  margin-top: 3rem;
  margin-bottom: 5rem;
}

.n-dialog .n-button {
  height: 5rem;
}

.n-dialog .n-dialog__icon {
  --n-icon-size: 56px;
}
</style>
