import { axios } from "@config/Http/axios";
import {
  userInfoEndpoint,
  UserTypeEndpoint,
  BUOUserCostCentersEndpoint,
  BUOUserExpenseCategoriesEndpoint,
  ELOUserExpenseCategoriesEndpoint,
  systemUsersEndpoint,
} from "@config/Constants/Endpoints";

export const UserService = {
  fetchUser(queryParams) {
    return axios.get(userInfoEndpoint, {
      params: { ...queryParams },
    });
  },

  fetchBUOUserCostCenters(userId) {
    return axios.get(`${BUOUserCostCentersEndpoint}?manager_id=${userId}`);
  },

  fetchBUOUserExpenseCategories(userId) {
    return axios.get(`${BUOUserExpenseCategoriesEndpoint}?manager_id=${userId}`);
  },

  fetchELOUserExpenseCategories(userId) {
    return axios.get(`${ELOUserExpenseCategoriesEndpoint}?manager_id=${userId}`);
  },

  fetchUserType(userId) {
    return axios.get(`${UserTypeEndpoint}?manager_id=${userId}`);
  },

  async fetchAllUsers() {
    return axios.get(systemUsersEndpoint);
  },
};
