<template>
  <div class="calendar page">
    <Gantt :data="initiatives" :years="groupedActivitiesYears" v-if="!isAppLoading" />
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapState, mapMutations } from "vuex";
import Gantt from "@/components/Chart/Gantt.vue";
import { InitiativesService } from "@services/Initiatives.service";
import { trycatch, TokenService } from "@utils";

const QUARTER_WIDTH = 15; // 15rem
const MONTH_IN_QUARTER_WIDTH = 5; // 3 months in quarter -> 15/3 = 5rem

export default {
  name: "CalendarPage",
  async created() {
    if (this.initiatives.length > 0) return; // avoid fetching if already fetched data

    this.SET_LOADING(true);
    const { waypoint_user_id: user_id } = TokenService.getUser();
    const userActivitiesPromise = InitiativesService.fetchUserActivities({ waypoint_user_id: user_id });
    const [error, response] = await trycatch(userActivitiesPromise);

    if (error) {
      // Show Error Notification
      console.log("calendarPage Error", error);
    }

    const grouped = this.groupBy(response.data ?? []);
    const groupedActivites = Object.values(grouped);

    // this.initiatives = groupedActivites;
    if (response.data?.length > 0) this.SET_ACTIVITIES(response.data);

    this.SET_GROUPED_ACTIVITES({ data: groupedActivites, years: this.years });
    this.SET_LOADING(false);
  },
  data() {
    return {
      QUARTER_WIDTH,
      MONTH_IN_QUARTER_WIDTH,
      years: [],
      // initiatives: this.groupedActivities,
    };
  },
  computed: {
    ...mapState({
      isAppLoading: "isAppLoading",
      initiatives: "groupedActivities",
      groupedActivitiesYears: "groupedActivitiesYears",
    }),
  },
  methods: {
    ...mapMutations(["SET_LOADING", "SET_GROUPED_ACTIVITES", "SET_ACTIVITIES"]),
    groupBy(activities) {
      const yearsSet = new Set();
      const groupedByInitiative = activities.reduce(function (r, activity) {
        r[activity.initiative_id] = r[activity.initiative_id] || {
          children: [],
        };

        r[activity.initiative_id].id = activity.initiative_id;
        r[activity.initiative_id].name = activity.initiative?.short_name;
        r[activity.initiative_id].color = "rgba(120, 121, 241, 0.15)";

        r[activity.initiative_id].children.push(activity);

        yearsSet.add(dayjs(activity.start_date).year());
        yearsSet.add(dayjs(activity.end_date).year());

        return r;
      }, Object.create(null));

      this.years = [...yearsSet].sort();

      return groupedByInitiative;
    },
  },
  components: {
    Gantt,
  },
};
</script>

<style>
.calendar.page {
  height: calc(100vh - 13rem);
  overflow: hidden;
}

.spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e9e5da;
  z-index: 10;
  font-size: 3rem;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.odd .node-tree.is-parent,
.node-tree.is-parent:nth-child(odd) {
  background: #f5f5f5;
}

.even .node-tree.is-parent {
  background: transparent;
}
</style>
