import { createApp } from "vue";
import App from "./App.vue";
import mitt from "mitt";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";
import { Icon } from "@vicons/utils";
import {
  Checkmark20Regular,
  Dismiss20Regular,
  ErrorCircle24Regular,
} from "@vicons/fluent";
import { SearchIcon } from "@components/Icons";
import Input from "@components/Common/Input.vue";
import { nanoid } from "nanoid";
import router from "./router";
import store from "./store";
import { OLD_UI_ROUTES } from "@config/Constants/routes";
import vClickOutside from "click-outside-vue3";

Amplify.configure({
  ...awsconfig,
  cookieStorage: {
    domain: import.meta.env.VITE_WAYPOINT_AWS_COOKIE_DOMAIN || 'localhost',
    secure: import.meta.env.VITE_WAYPOINT_AWS_COOKIE_SECURE || true,
    path: '/',
    expires: import.meta.env.VITE_WAYPOINT_AWS_COOKIE_EXPIRES ? Number(import.meta.env.VITE_WAYPOINT_AWS_COOKIE_EXPIRES) : 1,
  }
});
// worker.start();

const app = createApp(App);
const emitter = mitt();

app.component("Icon", Icon);
app.component("SearchIcon", SearchIcon);
app.component("NumberInput", Input);
app.component("Checkmark", Checkmark20Regular);
app.component("Dismiss", Dismiss20Regular);
app.component("Error", ErrorCircle24Regular);

app.use(Donut);
app.use(router);
app.use(store);
app.use(vClickOutside);

app.config.globalProperties.emitter = emitter;
app.config.globalProperties.OLD_UI_ROUTES = OLD_UI_ROUTES;
app.config.globalProperties.nanoid = nanoid;
app.config.globalProperties.currencyFormat = (x = 0, decimal = 0) =>
  Number(Number(x).toFixed(decimal)).toLocaleString();

app.mount("#app");
