export const TOKEN = "token";
export const WAYPOINT_USER = "waypoint_user";
export const TOKEN_EXPIRED = "TokenExpiredError";

export const AWS_AUTH_EVENT = {
  SIGNIN: "signIn",
  SIGNOUT: "signOut",
};

export const Activity = {
  COMPLETE: "complete",
};

export const Events = {
  INITIATED: "program_initiated_by_you",
  AWAITING: "program_awaiting_your_approval",
  APPROVED: "program_approved_by_you",
};

export const StakeholderType = {
  EXPENSE_ACCOUNT: "Expense Account",
  COST_CENTER: "Cost Center",
};

export const ExpenseAccount = {
  SALARIES: "EMPLOYEE SALARIES",
  RENT: "RENT SPACE LEASE",
  EQUIPMENT: "LEASED EQUIPMENT RENT",
};

export const LocalEvents = {
  ADD_EXPENSE_ACCOUNT: "addExpenseAccount",
  REMOVE_EXPENSE_ACCOUNT: "removeExpenseAccount",
};

export const InitiativeStates = {
  INITIATED: "initiated",
  AWAITING: "awaiting",
  APPROVED: "approved",
  DRAFT: "draft",
};

export const InitiativeStatus = {
  INREVIEW: "in_review",
  APPROVED: "approved",
  REJECTED: "rejected",
};

export const Pagination = {
  limit: 5,
};

// Vuex initial state
export const Initial = {
  state: {
    user: null,
  },
};

export const INITIATIVE_CARD_ROW_LABELS = {
  ID: "ID",
  SAVINGS_TYPE: "Savings Type",
  CATEGORY: "Category",
  OWNER: "Owner",
  DEPARTMENT: "Department",
};
