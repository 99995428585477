<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :class="{ 'custom-color': customColor }"
      d="M21 5.98001C17.67 5.65001 14.32 5.48001 10.98 5.48001C9 5.48001 7.02 5.58001 5.04 5.78001L3 5.98001"
      stroke="rgb(196 216 243)"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      :class="{ 'custom-color': customColor }"
      d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
      stroke="rgb(196 216 243)"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      :class="{ 'custom-color': customColor }"
      d="M18.85 9.14001L18.2 19.21C18.09 20.78 18 22 15.21 22H8.79002C6.00002 22 5.91002 20.78 5.80002 19.21L5.15002 9.14001"
      stroke="rgb(196 216 243)"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      :class="{ 'custom-color': customColor }"
      d="M10.33 16.5H13.66"
      stroke="#1264CE"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      :class="{ 'custom-color': customColor }"
      d="M9.5 12.5H14.5"
      stroke="#1264CE"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    customColor: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
path.custom-color {
  /* stroke: v-bind(color); */
  stroke: var(--n-error-color);
}
</style>
