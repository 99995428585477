<template>
  <div class="gantt-chart">
    <div class="tree">
      <ul class="tree-list">
        <node-tree :node="treeData" :isBar="isBar" />
      </ul>
    </div>
  </div>
</template>

<script>
import NodeTree from "./NodeTree.vue";

export default {
  props: {
    treeData: Object,
    isBar: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    NodeTree,
  },
};
</script>

<style>
.tree-list {
  display: flex;
  margin: 0;
  padding: 0;
}

.tree-list ul {
  padding-left: 16px;
  margin: 6px 0;
}

li.node-tree.is-bar,
li.node-tree.is-bar ul {
  padding: 0;
  list-style: none;
}

.node-tree:not(.is-bar) {
  /* width: 15rem; */
  list-style: none;
}

.node-tree.is-bar {
  flex-grow: 1;
}
/* .tree-list > .node-tree.is-bar {
  margin-left: 1rem;
} */
</style>
