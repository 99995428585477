<template>
  <div class="custom n-input" :class="{ readonly }">
    <div class="n-input-wrapper">
      <div class="n-input__input" :class="{ 'justify-right': right }">
        <input
          type="text"
          class="n-input__input-el"
          @blur="handleChange"
          ref="inputRef"
          :readonly="readonly"
          :value="modelValue"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useCurrencyInput } from "vue-currency-input";

export default {
  name: "CurrencyInput",
  emits: ["inputValue"],
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    options: {
      type: Object,
      default: () => ({
        currency: "USD",
        currencyDisplay: "hidden",
        hideGroupingSeparatorOnFocus: false,
      }),
    },
    right: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleChange(e) {
      this.$emit("inputValue", Number(e.target.value.replaceAll(",", "")));
    },
  },
  setup(props) {
    const { inputRef } = useCurrencyInput(props.options);

    return { inputRef };
  },
};
</script>

<style>
.custom.n-input {
  border: 1px solid #d1d1d1;
  background: #fff;
  padding-left: 1rem;
}

.custom.custom.n-input.readonly {
  background: var(--n-light-blue-color);
}

.custom .justify-right {
  text-align: right !important;
  padding-right: 1rem;
}

.custom.n-input {
  height: 3rem;
  border-radius: 8px !important;
}
</style>
