<template>
  <NSpace>
    <SearchInput />
    <!-- Filter Button -->
    <NButton size="large" class="light-blue filter-btn">
      <template #icon>
        <NIcon size="50">
          <FilterIcon />
        </NIcon>
      </template>
    </NButton>
    <!-- Create Button -->
    <NButton size="large" type="primary" class="bold500" @click="createNewInitiative">
      Create New
    </NButton>
  </NSpace>
</template>

<script>
import { SearchInput } from "@components/Common";
import { FilterIcon } from "@components/Icons";
import { Route } from "@config/Constants";
import { mapMutations } from "vuex";

export default {
  name: "ReviewApprovalHeader",
  methods: {
    ...mapMutations(["RESET_INITIATIVE_STATE"]),
    createNewInitiative() {
      this.RESET_INITIATIVE_STATE()

      this.$router.push({
        name: Route.CREATEINITIATIVE.Name,
      });
    },
  },
  components: {
    SearchInput,
    FilterIcon,
  },
};
</script>
