<template>
  <NSpace class="initiatives-list">
    <NScrollbar class="vertical-scroll">
      <NScrollbar x-scrollable style="max-width: 100vw; max-height: calc(100vh - 25rem)">
        <NSpace class="container-d">
          <NSpace
            vertical
            v-for="(column, i) in getReviewsAndApprovals"
            :key="i"
            style="width: 38rem"
          >
            <NSpace
              class="h2 bold600"
              justify="space-between"
              align="center"
              style="width: 100%"
            >
              <div>{{ column.title }}</div>
              <div class="value">${{ currencyFormat(column.value) }}</div>
            </NSpace>
            <NDivider style="margin-top: 1rem; margin-bottom: 0.7rem" />
            <template v-if="column.cards.length > 0">
              <Card
                :card="card"
                :dropDownOptions="getDropdownOptions(column, card)"
                @show-status="setInitiative"
                @delete-initiative="deleteInitiative"
                v-for="(card, j) in column.cards"
                :key="j"
              />
              <NButton
                text
                style="width: 100%; margin: 0 auto; margin-top: 1rem; font-weight: 500"
                @click="loadMoreInitiatives(column.event, column.state)"
                v-if="column.cards.length >= loadMore.limit && loadMore[column.state]"
              >
                Load more...
              </NButton>
            </template>
            <template v-else-if="isAppLoading">
              <NSkeleton text :repeat="2" />
              <NSkeleton text style="width: 60%" />
            </template>
            <div class="empty-card" v-else>No Initiative</div>
          </NSpace>
        </NSpace>
      </NScrollbar>
    </NScrollbar>
  </NSpace>
  <NModal v-model:show="showModal" :mask-closable="false" positive-text="Close">
    <n-card
      style="width: 90%"
      :bordered="false"
      size="huge"
      role="dialog"
      aria-modal="true"
    >
      <template #header>
        <div class="title">
          <span class="bold600">
            {{ modalInitiative.name }}
          </span>
          <p class="sub-title flex-column">
            <span> Due Date: &nbsp; {{ getMeta.completion }} </span>
            <span> Savings: &nbsp; ${{ getMeta.saving }} </span>
          </p>
        </div>
      </template>
      <ApprovalStatusList
        :initiativeId="modalInitiative?.id"
        :status="userAction"
        :override="overrideAll"
        :overridden="getMeta.overridden"
      />
      <template #footer>
        <div class="flex-grow flex flex-between">
          <div class="btn-group flex">
            <template v-if="getMeta.isOwner">
              <NButton
                color="#1cb79b"
                size="large"
                :disabled="getMeta.initiative?.approved"
                v-on:click="
                  {
                    click: getMeta.override ? (overrideAll = true) : null;
                  }
                "
              >
                Approval Override
              </NButton>
            </template>
            <template v-else-if="!getMeta.initiative?.approved">
              <NButton
                color="#1cb79b"
                size="large"
                :disabled="userAction === 'approved'"
                @click="userAction = 'approved'"
              >
                {{
                  getMeta.overridden
                    ? "Approved"
                    : userAction === "approved"
                    ? "You Approved"
                    : "Approve"
                }}
              </NButton>
              <NButton
                color="#F2994A"
                size="large"
                :disabled="userAction === 'in_review'"
                style="margin-left: 1rem"
                @click="userAction = 'in_review'"
              >
                In Review
              </NButton>
              <NButton
                color="#eb5757"
                size="large"
                :disabled="userAction === 'rejected'"
                style="margin-left: 1rem"
                @click="userAction = 'rejected'"
                v-if="!getMeta.isOwner"
              >
                {{ userAction === "rejected" ? "You Rejected" : "Reject" }}
              </NButton>
            </template>
            <NButton
              ghost
              color="#19a188"
              size="large"
              class="initiative-status"
              v-if="getMeta.initiative?.approved"
            >
              {{
                getMeta.overridden ? "Initiative Auto Approved" : "Initiative Approved"
              }}
            </NButton>
            <NButton
              ghost
              color="#eb5757"
              size="large"
              class="initiative-status"
              v-else-if="getMeta.initiative?.rejected"
            >
              Initiative Rejected
            </NButton>
          </div>
          <NButton color="#1264ce" size="large" @click="showModal = false">
            Close
          </NButton>
        </div>
      </template>
    </n-card>
  </NModal>
</template>

<script>
import { dateFormat } from "@utils";
import { Card } from "@components/Common";
import { mapActions, mapMutations, mapGetters, mapState } from "vuex";
import { InitiativeStates, InitiativeStatus, Pagination } from "@config/Constants";
import ApprovalStatusList from "@components/Initiatives/List/ApprovalStatusList.vue";

export default {
  name: "InitiativesListPage",
  async beforeCreate() {
    await this.$store?.dispatch("FetchInitiatives", this.$route.params.refetch == "true");
  },
  data: () => ({
    showModal: false,
    modalInitiative: null,
    userAction: "",
    overrideAll: false,
    loadMore: {
      [InitiativeStates.INITIATED]: true,
      [InitiativeStates.AWAITING]: true,
      [InitiativeStates.APPROVED]: true,
      [InitiativeStates.DRAFT]: true,
      limit: Pagination.limit,
    },
    dropDownOptions: [
      {
        label: "View Messages",
        key: "messages",
      },
      {
        label: "View Details",
        key: "details",
      },
      {
        label: "View Status",
        key: "status",
      },
    ],
    draftDropDownOptions: [
      {
        label: "Update",
        key: "update",
      },
    ],
  }),
  computed: {
    ...mapState(["isAppLoading", "user"]),
    ...mapGetters(["getReviewsAndApprovals"]),
    getMeta() {
      const initiative = this.modalInitiative;
      if (!initiative) return {};
      const userId = this.user.waypoint_user_id;
      const active = initiative.initiativeActivities.find(
        a => a.savings_realization_check == 1
      );
      const loggedUser = initiative.stakeholder_manifests.find(u => u.user_id == userId);
      const isOwner =
        initiative.initiative_owner == userId || initiative.created_by == userId;
      const userAction =
        loggedUser?.stakeholder_manifest_status.status ?? InitiativeStatus.APPROVED;

      this.userAction = userAction;

      return {
        isOwner,
        userAction,
        override: isOwner && !initiative.approve_override, // approve_override = false -> did not override yet
        overridden: initiative.approve_override,
        completion: dateFormat(active.end_date),
        saving: this.currencyFormat(
          initiative.input_savings ?? initiative.input_estimate
        ),
        initiative: {
          approved: initiative.initiative_status === InitiativeStatus.APPROVED,
          rejected: initiative.initiative_status === InitiativeStatus.REJECTED,
          review: initiative.initiative_status === InitiativeStatus.INREVIEW,
        },
      };
    },
  },
  methods: {
    ...mapMutations(["SET_ERROR", "SET_LOADING"]),
    ...mapActions(["FetchInitiatives", "LoadMoreInitiatives", "DeleteInitiative"]),
    setInitiative(obj) {
      this.modalInitiative = obj;
      this.showModal = true;
    },
    loadMoreInitiatives(event, initiativeState) {
      this.LoadMoreInitiatives({ event, initiativeState }).then(fetchedItems => {
        if (fetchedItems?.length < Pagination.limit) {
          this.loadMore[initiativeState] = false;
        }
      });
    },
    deleteInitiative(id) {
      this.SET_LOADING(true);
      this.DeleteInitiative(id).then(async ([err, res]) => {
        if (err) {
          this.SET_ERROR({
            type: "error",
            message: "Error occurred while deleting initiative.",
            meta: "Try to reload the page",
          });
          return;
        }
        const REFETCH = true;
        await this.FetchInitiatives(REFETCH);
      });
      this.SET_LOADING(false);
    },
    getDropdownOptions(column, card) {
      const { raw } = card;
      const isDraft = column.title.includes("Draft");
      const isOwner = raw?.initiative_owner == this.user.waypoint_user_id;
      const options = isDraft
        ? [...this.draftDropDownOptions]
        : [...this.dropDownOptions];

      if (isOwner) {
        options.push({ type: "divider", key: "d1" });
        options.push({ label: "Remove", key: "remove" });
      }

      return options;
    },
  },
  components: {
    Card,
    ApprovalStatusList,
  },
};
</script>

<style>
.n-dropdown-divider + .n-dropdown-option:last-child .n-dropdown-option-body__label {
  color: var(--n-error-color);
}

.initiatives-list .container-d {
  flex-flow: nowrap !important;
  margin-bottom: 8rem !important;
  padding-right: 3.5rem;
}

.initiatives-list .container-d > div:not(:first-child) {
  margin-left: 1.5rem;
}

.initiatives-list > div {
  min-width: 100%;
}

#app .ReviewsPage {
  height: 100vh;
}

.ReviewsPage .n-layout-content {
  padding-right: 0 !important;
}

.ReviewsPage .n-scrollbar-rail.n-scrollbar-rail--vertical {
  right: 0;
}

.ReviewsPage .n-divider__line {
  height: 2px;
}

.ReviewsPage .light-blue svg {
  margin-top: 1.5rem;
}

.n-card.n-modal .btn-group {
  width: 45rem;
}

.initiative-status {
  margin-left: 1rem;
  font-weight: bold;
  cursor: text;
  pointer-events: none;
}

/* Scrollbar */
/* .initiatives-list .n-scrollbar-content {
  height: 80vh;
  width: 93vw;
  overflow: auto;
} */
</style>
