import { axios } from "@config/Http/axios";
import {
  userInitiativesEndpoint,
  draftInitiativesEndpoint,
  initiativeActivitiesEndpoint,
  initiativeActivities2Endpoint,
  initiativeCategoriesEndpoint,
  businessExpenseTypesEndpoint,
  costCentersHierarchyEndpoint,
  initiativeStakeholderManifestEndpoint,
  generateStakeholdersEndpoint,
  expenseAccountCategoriesEndpoint,
  expenseCategoriesEndpoint,
  initiativeCreateEndpoint,
  initiativeTypesEndpoint,
  initiativeCostCentersEndpoint,
  expenseCategories2Endpoint,
  initiativeExpenseCategories,
  initiativeRevenue,
  projectCost,
  initiativeStep,
  initiatives,
  initiativeStakeholders,
  duplicateInitiatives,
  initiativeHeadcount,
  initiativeStatus,
  approvalOverride,
  deleteInitiative,
} from "@config/Constants/Endpoints";

export const InitiativesService = {
  fetchUserInitiatives(queryParams) {
    return axios.get(userInitiativesEndpoint, {
      params: { ...queryParams },
    });
  },

  fetchDraftInitiatives(queryParams) {
    return axios.get(draftInitiativesEndpoint, {
      params: { ...queryParams },
    });
  },

  fetchUserActivities(queryParams) {
    return axios.get(initiativeActivitiesEndpoint, {
      params: { ...queryParams },
    });
  },

  createUserActivities(payload) {
    return axios.post(initiativeActivities2Endpoint, { ...payload });
  },

  fetchInitiativeCategories() {
    return axios.get(initiativeCategoriesEndpoint);
  },

  fetchInitiativeExpenseTypes() {
    return axios.get(businessExpenseTypesEndpoint);
  },

  fetchInitiativeCostCentersHierarchy(payload) {
    return axios.post(costCentersHierarchyEndpoint, { ...payload });
  },

  fetchInitiativeStakeholdersManifest(initiative_id) {
    return axios.get(`${initiativeStakeholderManifestEndpoint}/${initiative_id}`);
  },

  generateStakeholders(initiative_id) {
    return axios.get(`${generateStakeholdersEndpoint}/${initiative_id}`);
  },

  fetchExpenseCategoriesByAccount(payload) {
    return axios.post(expenseAccountCategoriesEndpoint, {
      ...payload,
    });
  },

  fetchExpenseCategories() {
    return axios.get(expenseCategoriesEndpoint);
  },

  createInitiative(payload) {
    return axios.post(initiativeCreateEndpoint, { ...payload });
  },

  fetchInitiativeTypes() {
    return axios.get(initiativeTypesEndpoint);
  },

  createInitiativeCostCenters(payload) {
    return axios.post(initiativeCostCentersEndpoint, { ...payload });
  },

  fetchInitiativeExpenseCategories2(payload) {
    return axios.post(expenseCategories2Endpoint, { ...payload });
  },

  fetchInitiativeExpenseCategories(payload) {
    return axios.post(initiativeExpenseCategories, { ...payload });
  },

  fetchRevenue(payload) {
    return axios.post(initiativeRevenue, { ...payload });
  },

  createProjectCost(payload) {
    return axios.post(projectCost, { ...payload });
  },

  saveInitiativeStep(payload) {
    return axios.put(initiativeStep, { ...payload });
  },

  fetchInitiative(initiative_id) {
    return axios.get(`${initiatives}/${initiative_id}`);
  },

  fetchInitiativeStakeholders(initiative_id) {
    return axios.get(`${initiativeStakeholders}/${initiative_id}`);
  },

  fetchDuplicateInitiatives(payload) {
    return axios.post(duplicateInitiatives, { ...payload });
  },

  fetchInitiativeHeadcounts(payload) {
    return axios.post(initiativeHeadcount, { ...payload });
  },

  updateInitiativeStatus(payload) {
    return axios.put(initiativeStatus, { ...payload });
  },

  overrideInitiativeApproval(initiative_id) {
    return axios.put(`${approvalOverride}/${initiative_id}`);
  },

  deleteInitiative(initiative_id) {
    return axios.delete(`${deleteInitiative}/${initiative_id}`);
  },
};
