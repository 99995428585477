import axios from "axios";
import { Auth } from "aws-amplify";
import { trycatch, TokenService } from "@utils";
import store from "../../store";
import { TOKEN_EXPIRED } from "@config/Constants";

const defaultOptions = {
  baseURL: import.meta.env.VITE_WAYPOINT_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    accesstoken: TokenService.getToken() || "",
  },
};

let instance = axios.create(defaultOptions);

instance.interceptors.request.use(config => {
  const token = TokenService.getToken();

  if (token) config.headers.accesstoken = token;

  return config;
});

instance.interceptors.response.use(
  response => {
    return response;
  },
  async function (error) {
    // Do something with response error
    if (error.response?.status === 401) {
      // 1: If token expired
      if (error.response.data.name === TOKEN_EXPIRED) {
        store.commit("SET_ERROR", {
          type: "warning",
          message: "Session expired. Please wait while we renew it.",
          meta: "Only few seconds :)",
        });

        const [sessionError, userSession] = await trycatch(Auth.currentSession()); // 2: Refresh Session

        if (sessionError || !userSession) {
          logout();
        }

        const token = userSession.getAccessToken().jwtToken; // 3: Get updated token

        TokenService.setToken(token);
        error.config.headers.accesstoken = token; // 4: update header with latest token

        return instance.request(error.config); // 5: Make same request once more
      } else {
        logout();
      }
    }

    return Promise.reject(error.response);
  }
);

function logout() {
  console.log("unauthorized, logging out ...");
  TokenService.clearStorage();
  store.commit("RESET_STATE");
}

export { instance as axios };
