import { Auth } from "aws-amplify";
import Cookies from "js-cookie";
import { TOKEN, WAYPOINT_USER } from "@config/Constants";

// Current date
const date = new Date();
const {
  VITE_WAYPOINT_AWS_COOKIE_DOMAIN,
  VITE_WAYPOINT_AWS_COOKIE_SECURE,
  VITE_WAYPOINT_AWS_COOKIE_EXPIRES,
} = import.meta.env;

date.setTime(date.getTime() + VITE_WAYPOINT_AWS_COOKIE_EXPIRES * 24 * 60 * 60 * 1000);

const cookieOptions = {
  expires: date,
  domain: VITE_WAYPOINT_AWS_COOKIE_DOMAIN,
  secure: VITE_WAYPOINT_AWS_COOKIE_SECURE,
};

export const TokenService = {
  getToken() {
    return getCookie(TOKEN)?.trim() ?? "";
  },

  setToken(accessToken) {
    setCookie(TOKEN, accessToken, cookieOptions);
  },

  getUser() {
    let localUser = localStorage.getItem(WAYPOINT_USER);
    localUser = localUser ? JSON.parse(localUser.trim()) : {};

    let cookieUser = getCookie(WAYPOINT_USER);
    cookieUser = cookieUser ? JSON.parse(cookieUser.trim()): {};

    return {...localUser, ...cookieUser};
  },

  setUser({ cookieUser, localUser } = null) {
    setCookie(WAYPOINT_USER, JSON.stringify(cookieUser), cookieOptions);
    localStorage.setItem(WAYPOINT_USER, JSON.stringify(localUser));
  },

  async clearStorage() {
    const removeCookieOptions = {
      domain: cookieOptions.domain
    }
    Cookies.remove(TOKEN, removeCookieOptions);
    Cookies.remove(WAYPOINT_USER, removeCookieOptions);
    localStorage.clear();
    try {
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
    console.log("TC!!!");
  },

  async isUserLoggedIn() {
    const cognitoUser = await Auth.currentUserInfo() ? true : false;
    const localUser = localStorage.getItem(WAYPOINT_USER) ? true : false
    return cognitoUser && localUser
  },

  /**
   * To check is set localstorage has WAYPOINT_USER
   * @returns boolean
   */
  isSetLocalStorageUser() {
    let localUser = localStorage.getItem(WAYPOINT_USER);
    localUser = localUser ? JSON.parse(localUser.trim()) : {};
    return Object.keys(localUser).length !== 0;
  }
};

/**
 * Returns a cookie value if a name is specified. Otherwise returns the entire cookies as an object
 * @param [name] - The name of the cookie to fetch the value for. Returns the entire map of cookies if not specified
 * @returns {string|Object} - The value of the cookie specified by `name` if specified. Otherwise returns a name value map of the available cookies
 */
export function getCookie(name) {
  return Cookies.get(name);
}

/**
 *
 * @param name - The name of the cookie to be set
 * @param value - The value of the cookie
 * @param options - supports any cookie option like path, expires, maxAge and domain. [MDN Cookie Reference](https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie)
 */
export function setCookie(name, value, options = {}) {
  Cookies.set(name, value, options);
}
