export const userInitiativesEndpoint = "/user-initiatives";
export const draftInitiativesEndpoint = "/draft-initiatives";
export const initiativeActivitiesEndpoint = "/initiative-activities";
export const initiativeCategoriesEndpoint = "/initiative-categories";
export const initiativeStakeholderManifestEndpoint = "/stakeholder-manifest";
export const generateStakeholdersEndpoint = "/generate-stakeholders";
export const initiativeExpenseCategories = "/initiative-expense-categories-v3";
export const initiativeCreateEndpoint = "/initiative-overview";
export const initiativeTypesEndpoint = "/initiative-types";
export const initiativeCostCentersEndpoint = "/initiative-cost-centers";
export const initiativeActivities2Endpoint = "/initiative-activities2";
export const initiativeRevenue = "/get-initiative-revenue-input";
export const projectCost = "/initiative-project-cost";
export const initiativeStep = "/save-initiative-step";
export const initiatives = "/initiatives";
export const draftInitiative = "/draft-initiative-by-id";
export const initiativeStakeholders = "/initiative-stakeholders";
export const duplicateInitiatives = "/initiatives-by-expense-account";
export const initiativeStatus = "/stakeholder-manifest-by-params";
export const approvalOverride = "/stakeholder-approval-override";
export const deleteInitiative = "/v2/initiatives";
