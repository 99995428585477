import dayjs from "dayjs";

export const formatDate = (startDate, endDate, onlyStartDate = false) => {
  startDate = dayjs(startDate);
  endDate = dayjs(endDate);

  if (onlyStartDate) return startDate.format("MMM DD, YYYY");

  const [isSameMonth, isSameYear] = [
    startDate.isSame(endDate, "month"),
    startDate.isSame(endDate, "year"),
  ];
  const date = isSameMonth // sameMonth ? format -> Month Date1 - Date2, Year
    ? `${startDate.format("MMM DD")}-${endDate.format("DD, YYYY")}`
    : isSameYear // sameYear ? format -> Month Date1 - Month Date2, Year
    ? `${startDate.format("MMM DD")} - ${endDate.format("MMM DD, YYYY")}`
    : `${startDate.format("MMM DD, YYYY")} - ${endDate.format("MMM DD, YYYY")}`;

  return date;
};

export const dateFormat = (date, formatType = "YYYY-MM-DD") => {
  if(!date) return null;
  return dayjs(date).format(formatType)
}
