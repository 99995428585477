<template>
  <NCard>
    <template #header>
      <NSpace justify="space-between" class="flex-centered">
        <NEllipsis
          style="width: 28rem"
          :tooltip="{
            placement: 'bottom-start',
          }"
          :line-clamp="1"
        >
          <p
            class="h2 bold600 no-margin"
            style="cursor: pointer; text-transform: capitalize"
            @click="toggle"
          >
            {{ card.title }}
          </p>
        </NEllipsis>
        <NDropdown
          placement="bottom-start"
          trigger="click"
          size="large"
          :options="dropDownOptions"
          @select="handleRoute"
        >
          <NIcon style="cursor: pointer">
            <MoreIcon />
          </NIcon>
        </NDropdown>
      </NSpace>
      <NSpace
        class="flex-centered h4 bold500"
        :class="{ 'border-bottom': isOpen }"
        style="margin-top: 0.7rem"
      >
        <NIcon>
          <CalendarIcon />
        </NIcon>
        {{ card.date }}
        <NSpace class="gray-text">
          Savings:
          <span class="success-text"> ${{ currencyFormat(card.savings) }} </span>
        </NSpace>
      </NSpace>
    </template>
    <!-- Card Content -->
    <template v-if="isOpen">
      <NSpace
        :justify="i == 1 ? 'flex-start' : 'space-between'"
        class="border-bottom h4 bold500"
        style="flex-flow: nowrap"
        v-for="(row, i) in card.rows"
        :key="i"
      >
        <NSpace
          vertical
          class="gray-text"
          :style="{
            width: calcCardContentWidth(j, col),
            wordWrap: 'break-word',
          }"
          v-for="(col, j) in row"
          :key="j"
        >
          <span>{{ col.label }}</span>
          <span
            class="dark-text"
            :style="{
              paddingRight: `${j == 0 ? 5 : 0}px`,
            }"
            >{{ col.value }}</span
          >
        </NSpace>
      </NSpace>
      <NSpace class="flex-centered h4 bold500 gray-text">
        <NIcon>
          <CalendarIcon />
        </NIcon>
        Response Due:
        <div class="warning-text">{{ card.due }}</div>
      </NSpace>
    </template>
  </NCard>
  <n-modal
    v-model:show="showModal"
    preset="dialog"
    title="Are you sure you want to delete this initiative?"
    content="Caution!!! You will loose this initiative after this action."
    positive-text="Yes"
    negative-text="No"
    class="danger-modal"
    @positive-click="deleteInitiative"
    @negative-click="showModal = false"
  />
</template>

<script>
import { MoreIcon, CalendarIcon } from "@components/Icons";
import { INITIATIVE_CARD_ROW_LABELS } from "@config/Constants";
import { Route } from "@config/Constants";
import { mapMutations } from "vuex";

export default {
  emits: ["show-status", "delete-initiative"],
  props: {
    dropDownOptions: {
      type: Array,
      required: true,
    },
    card: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isOpen: false,
    showModal: false,
  }),
  computed: {
    calcCardContentWidth() {
      return (j, col) =>
        [
          INITIATIVE_CARD_ROW_LABELS.OWNER,
          INITIATIVE_CARD_ROW_LABELS.DEPARTMENT,
        ].includes(col.label)
          ? "auto"
          : j == 0
          ? "80px "
          : "110px";
    },
  },
  methods: {
    ...mapMutations(["RESET_INITIATIVE_STATE"]),
    toggle() {
      this.isOpen = !this.isOpen;
    },
    handleRoute(key) {
      if (key === "update") {
        this.$router.push({
          name: Route.CREATEINITIATIVE.Name,
          params: { id: this.card.raw.initiative_id },
          query: { step: 1 },
        });
      } else if (key === "details") {
        window.location.replace(`${this.OLD_UI_ROUTES.LOOK_UP}/${this.card.id}`);
      } else if (key === "status") {
        this.$emit("show-status", {
          ...this.card?.raw,
          id: this.card?.raw?.initiative_id,
          name: this.card?.raw.short_name,
        });
      } else if (key === "messages") {
        window.location.replace(this.OLD_UI_ROUTES.INITIATIVE_COMMENTS);
      } else if (key === "remove") {
        this.showModal = true;
      }
    },
    deleteInitiative() {
      this.$emit("delete-initiative", this.card?.raw?.initiative_id);
      this.showModal = false;
    },
  },
  components: {
    MoreIcon,
    CalendarIcon,
  },
};
</script>

<style>
.n-card {
  width: 38rem;
  padding: 16px;
}

.n-card-header .n-space.flex-centered > div {
  display: flex;
  align-items: center;
}

.n-card-header .flex-centered:first-child > div:first-child {
  width: 90%;
}

.border-bottom {
  border-bottom: 1px solid #e5f2ff;
  padding-bottom: 1.2rem;
}

.initiatives .flex-centered > div:first-child {
  display: flex;
  align-items: center;
  font-size: 1.75rem;
}

.initiatives .n-space.gray-text > div:first-child {
  margin-bottom: 0 !important;
}

.n-card__content .n-space.gray-text {
  position: relative;
}

.n-card__content .n-space > div:not(:last-child) .n-space.gray-text:after {
  content: "";
  position: absolute;
  right: -3px;
  height: 60%;
  width: 1px;
  background: #e5f2ff;
  top: 25%;
}

.n-card__content > .n-space {
  padding-top: 1.2rem;
}
</style>
